import { objGetNested } from "@/fonctions";

export const getError = (error) => {
  const errorMessage = "Erreur de connexion à la base de données !";

  if (error.name === "Fetch User") {
    return error.message;
  }

  if (!error.response) {
    console.error(`API ${error.config.url} not found`);
    return errorMessage;
  }
  if (process.env.NODE_ENV === "development") {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  }
  if (error.response.data && error.response.data.errors) {
    return error.response.data.errors;
  }
  return errorMessage;
};
export const getUserError = (error) => {
  const errorMessage = "Erreur de connexion à la base de données !";
  if (error.name === "Fetch User") {
    return error.message;
  }

  if (!error.response) {
    console.error(`API ${error.config.url} not found`);
    return errorMessage;
  }
  if (process.env.NODE_ENV === "development") {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  }
  // Erreur au Login
  const emailError = objGetNested(error, [
    "response",
    "data",
    "errors",
    "email",
  ]);
  if (emailError) {
    return emailError[0];
  }
  // Erreur sur le mot de passe actuel
  const currentPwError = objGetNested(error, [
    "response",
    "data",
    "errors",
    "current_password",
  ]);
  if (currentPwError) {
    return "Le mot de passe actuel est incorrect !";
  }
  // Si aucune erreur identifié, retourne le message de base
  return errorMessage;
};
