import UserService from "@/services/UserService";
import { toLocaleDateStr } from "@/fonctions";
import store from "@/store/index.js";

const state = {
  users: [],
  usersLoadStatus: 0,
  user: {},
  userLoadstatus: 0,
  userLoadStatus: 0,
  userCreateStatus: 0,
  userLastCreatedId: 0,
  userUpdateStatus: 0,
  userDeleteStatus: 0,
  actions: {
    delete: "user/deleteUser",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "name",
      label: "Nom d'utilisateur",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "email",
      label: "Email",
      typefiltre: "autocompl",
      visible: true,
      email: true,
    },
    {
      prop: "isAdmin",
      label: "Administrateur",
      typefiltre: "checkbool",
      visible: true,
      bool: true,
    },
    {
      prop: "last_login_at",
      label: "Dernière connexion",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "created_at",
      label: "Créé le",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "updated_at",
      label: "Dernière maj",
      typefiltre: "jdate",
      visible: true,
    },
  ],
};
const getters = {
  // liste des emails des users existants
  UsersInBD: (state) => {
    return state.users.map((u) => [u.id, u.email]);
  },
};
const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },
  resetUser(state) {
    state.user = {
      id: "",
      name: "",
      email: "",
      isAdmin: false,
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setUsersLoadStatus(state, status) {
    state.usersLoadStatus = status;
  },
  setUserLoadStatus(state, status) {
    state.userLoadStatus = status;
  },
  setUserCreateStatus(state, status) {
    state.userCreateStatus = status;
  },
  setUserLastCreatedId(state, idth) {
    state.userLastCreatedId = idth;
  },
  setUserUpdateStatus(state, status) {
    state.userUpdateStatus = status;
  },
  setUserDeleteStatus(state, status) {
    state.userDeleteStatus = status;
  },
  userRemoveItem(state, iduser) {
    state.users = state.users.filter((u) => u.id != iduser);
  },
};

const actions = {
  // charge l'ensemble ses users
  loadUsers({ commit, rootGetters }) {
    commit("setUsersLoadStatus", 1);
    UserService.getUsers()
      .then((response) => {
        const rep = response.data.data;
        rep.map((o, idx) => {
          o.created_at = toLocaleDateStr(o.created_at);
          o.updated_at = toLocaleDateStr(o.updated_at);
          o.last_login_at = toLocaleDateStr(o.last_login_at);
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
          // empêche de supprimer l'utilisateur connecté
          o.nodelete = o.email == rootGetters["auth/authUser"].email;
        });
        commit("setUsers", rep);
        commit("setUsersLoadStatus", 2);
      })
      .catch(() => {
        commit("setUsers", []);
        commit("setUsersLoadStatus", 3);
      });
  },
  // charge 1 user
  loadUser({ commit }, iduser) {
    commit("setUserLoadStatus", 1);
    UserService.getUser(iduser)
      .then(function (response) {
        commit("setUser", response.data.data);
        commit("setUserLoadStatus", 2);
      })
      .catch(function () {
        commit("setUser", {});
        commit("setUserLoadStatus", 3);
      });
  },
  // Créer un nouvel user :
  // met les données du user créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createUser({ commit, dispatch }, data) {
    commit("setUserCreateStatus", 1);

    UserService.createUser(data.name, data.email, data.isAdmin)
      .then(function () {
        dispatch("loadUsers");
        commit("setUserCreateStatus", 2);
        commit("resetUser");
      })
      .catch(function () {
        commit("setUserCreateStatus", 3);
      });
  },
  // Met à jour les données d'un user
  updateUser({ commit, dispatch }, data) {
    commit("setUserUpdateStatus", 1);
    UserService.updateUser(data.id, data.name, data.email, data.isAdmin)
      .then(function (response) {
        dispatch("loadUsers");
        commit("setUser", response.data.data);
        commit("setUserUpdateStatus", 2);
        store.dispatch("auth/getAuthUser");
      })
      .catch(function () {
        commit("setUserUpdateStatus", 3);
      });
  },
  // supprime 1 user
  deleteUser({ commit, dispatch }, idUser) {
    if (idUser == store.getters["auth/authUser"].id) {
      // évite de supprimer l'utilisateur connecté
      commit("setUserDeleteStatus", 3);
    } else {
      commit("setUserDeleteStatus", 1);
      UserService.deleteUser(idUser)
        .then(function () {
          commit("setUserDeleteStatus", 2);
          dispatch("loadUsers");
        })
        .catch(function () {
          commit("setUserDeleteStatus", 3);
        });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
