// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import pcactiviteAPI from "@/services/rh/pcactivite.js";

const state = {
  pcactivites: [],
  pcactivitesLoadStatus: 0,
};

const mutations = {
  setPcactivites(state, pcactivites) {
    state.pcactivites = pcactivites;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setPcactivitesLoadStatus(state, status) {
    state.pcactivitesLoadStatus = status;
  },
};

const actions = {
  loadPcactivites({ commit }) {
    commit("setPcactivitesLoadStatus", 1);
    pcactiviteAPI
      .activiteIndex()
      .then(function (response) {
        commit("setPcactivites", response.data);
        commit("setPcactivitesLoadStatus", 2);
      })
      .catch(function () {
        commit("setPcactivites", []);
        commit("setPcactivitesLoadStatus", 3);
      });
  },
};

const getters = {
  pcactiviteSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....]
    return state.pcactivites.map((obj) => {
      return obj.secteur
        ? { value: obj.id, label: obj.activite_abrev + " - " + obj.secteur }
        : { value: obj.id, label: obj.activite_abrev };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
