// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import fcontinueAPI from "@/services/rh/fcontinue.js";

const state = {
  fcontinues: [],
  fcontinuesLoadStatus: 0,
  fcontinue: {},
  fcontinueLoadStatus: 0,
  fcontinueCreateStatus: 0,
  fcontinueLastCreatedId: 0,
  fcontinueUpdateStatus: 0,
  fcontinueDeleteStatus: 0,
  actions: {
    delete: "fc/deleteFcontinue",
  },
  // table
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_fcontinues", params: { idmed: "id_med" } },
    },
    {
      prop: "categorie",
      label: "Type",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "titre",
      label: "Titre",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "autre_prec",
      label: "Titre précisions",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "annee_mois",
      label: "Date",
      typefiltre: "multichoix",
      visible: true,
    },
  ],
  // formulaire
  //options pour le champ mois
  optMois: [
    { value: "Inconnu", label: "-- Inconnu --" },
    { value: "01", label: "Janvier" },
    { value: "02", label: "Février" },
    { value: "03", label: "Mars" },
    { value: "04", label: "Avril" },
    { value: "05", label: "Mai" },
    { value: "06", label: "Juin" },
    { value: "07", label: "Juillet" },
    { value: "08", label: "Août" },
    { value: "09", label: "Septembre" },
    { value: "10", label: "Octobre" },
    { value: "11", label: "Novembre" },
    { value: "12", label: "Décembre" },
  ],
  // formulaire : options pour le champ annee (années ajoutées avec getters)
  optAnneeInconnu: [{ value: "Inconnue", label: "-- Inconnue --" }],
};

const getters = {
  // options pour le champ annee : inconnu + année actuelle jusqu'à - 45 an
  optAnnee: (state) => {
    let optArray = state.optAnneeInconnu;
    const curYear = new Date().getFullYear(); // année en cours
    // loop pour récupérer les années
    for (let i = curYear; i >= curYear - 45; i--) {
      const iStr = i.toString(); // année en string
      optArray.push({ value: iStr, label: iStr });
    }

    return optArray;
  },
  // dans form create pour vérifier si titre déjà attribué au méd.
  FcontinueInBD: (state) => {
    return state.fcontinues.map((f) => [
      f.id,
      f.id_med,
      f.id_fcont_titre,
      f.annee_mois,
    ]);
  },
  // récupère le id_fcont_titre, utilisé dans Delete pour vérifier si le titre supprimé est dans les checklist des Fpostgrads
  idFcontTitre: (state) => (idfcont) => {
    return state.fcontinues.filter((obj) => obj.id == idfcont)[0]
      .id_fcont_titre;
  },
};

const mutations = {
  setFcontinues(state, fcontinues) {
    state.fcontinues = fcontinues;
  },
  setFcontinue(state, fcontinue) {
    state.fcontinue = fcontinue;
  },
  resetFcontinue(state, idmed) {
    state.fcontinue = {
      id: "",
      id_med: idmed,
      id_fcont_titre: "",
      autre_prec: "",
      mois: "",
      annee: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setFcontinuesLoadStatus(state, status) {
    state.fcontinuesLoadStatus = status;
  },
  setFcontinueLoadStatus(state, status) {
    state.fcontinueLoadStatus = status;
  },
  setFcontinueCreateStatus(state, status) {
    state.fcontinueCreateStatus = status;
  },
  setFcontinueLastCreatedId(state, idfc) {
    state.fcontinueLastCreatedId = idfc;
  },
  setFcontinueUpdateStatus(state, status) {
    state.fcontinueUpdateStatus = status;
  },
  setFcontinueDeleteStatus(state, status) {
    state.fcontinueDeleteStatus = status;
  },
  fcontinueRemoveItem(state, idfc) {
    state.fcontinues = state.fcontinues.filter((cont) => cont.id != idfc);
  },
};

const actions = {
  // charge l'ensemble des fcontinues
  loadFcontinues({ commit }) {
    commit("setFcontinuesLoadStatus", 1);
    fcontinueAPI
      .fcontinueIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setFcontinues", response.data);
        commit("setFcontinuesLoadStatus", 2);
      })
      .catch(function () {
        commit("setFcontinues", []);
        commit("setFcontinuesLoadStatus", 3);
      });
  },
  // charge 1 fcontinue
  loadFcontinue({ commit }, idFpg) {
    commit("setFcontinueLoadStatus", 1);
    fcontinueAPI
      .fcontinueShow(idFpg)
      .then(function (response) {
        commit("setFcontinue", response.data);
        commit("setFcontinueLoadStatus", 2);
      })
      .catch(function () {
        commit("setFcontinue", {});
        commit("setFcontinueLoadStatus", 3);
      });
  },
  // Créer un nouveau fcontinue :
  // met les données du fcontinue créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createFcontinue({ commit, dispatch, rootGetters }, data) {
    commit("setFcontinueCreateStatus", 1);
    fcontinueAPI
      .fcontinueCreate(
        data.id_med,
        data.id_fcont_titre,
        data.autre_prec,
        data.mois,
        data.annee
      )
      .then(function (response) {
        dispatch("loadFcontinues");
        // Recharge aussi la liste des titres de fcontinue pour maj le nbre d'occurence
        dispatch("fctit/loadTitres", null, { root: true });
        // si la fcont est incluse dans les checklits des Fpostgrads recharge aussi la table des Fpostgrad car le statut des fortmations avec checklist peut avoir changé
        if (
          rootGetters["fctit/IdTitresInClFpg"].includes(data.id_fcont_titre)
        ) {
          dispatch("fpg/loadFpostgrads", null, { root: true });
        }
        commit("setFcontinueCreateStatus", 2);
        commit("setFcontinueLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setFcontinueCreateStatus", 3);
      });
  },
  // Met à jour les données d'un fcontinue
  updateFcontinue({ commit, dispatch, getters, rootGetters }, data) {
    commit("setFcontinueUpdateStatus", 1);
    const idTitreAvant = getters.idFcontTitre(data.id);
    const idTitreApres = data.id_fcont_titre;
    fcontinueAPI
      .fcontinueUpdate(
        data.id,
        data.id_med,
        data.id_fcont_titre,
        data.autre_prec,
        data.mois,
        data.annee
      )
      .then(function (response) {
        dispatch("loadFcontinues");
        commit("setFcontinue", response.data);
        commit("setFcontinueUpdateStatus", 2);
        // Recharge aussi la liste des titres de fcontinue pour maj le nbre d'occurence
        dispatch("fctit/loadTitres", null, { root: true });
        // si la fcont est incluse dans les checklits des Fpostgrads recharge aussi la table des Fpostgrad car le statut des fortmations avec checklist peut avoir changé
        const IdTitCl = rootGetters["fctit/IdTitresInClFpg"];
        if (IdTitCl.includes(idTitreAvant) || IdTitCl.includes(idTitreApres)) {
          dispatch("fpg/loadFpostgrads", null, { root: true });
        }
      })
      .catch(function () {
        commit("setFcontinueUpdateStatus", 3);
      });
  },
  // supprime 1 fcontinue
  deleteFcontinue({ commit, dispatch, getters, rootGetters }, idfc) {
    commit("setFcontinueDeleteStatus", 1);
    const idTitre = getters.idFcontTitre(idfc);
    fcontinueAPI
      .fcontinueDelete(idfc)
      .then(function () {
        commit("setFcontinueDeleteStatus", 2);
        dispatch("loadFcontinues");
        // Recharge aussi la liste des titres de fcontinue pour maj le nbre d'occurence
        dispatch("fctit/loadTitres", null, { root: true });
        // si la fcont est incluse dans les checklits des Fpostgrads recharge aussi la table des Fpostgrad car le statut des fortmations avec checklist peut avoir changé
        if (rootGetters["fctit/IdTitresInClFpg"].includes(idTitre)) {
          dispatch("fpg/loadFpostgrads", null, { root: true });
        }
      })
      .catch(function () {
        commit("setFcontinueDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
