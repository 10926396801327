// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import medecinAPI from "@/services/rh/medecin";
import { strNormalize } from "@/fonctions";

const state = {
  medecins: [],
  medecinsLoadStatus: 0,
  medecin: {},
  medecinLoadStatus: 0,
  medecinCreateStatus: 0,
  medecinLastCreatedId: 0,
  medecinUpdateStatus: 0,
  medecinDeleteStatus: 0,
  medecinPhotoUploadStatus: 0,
  medecinPhotoDeleteStatus: 0,
  medecinCvUploadStatus: 0,
  medecinCvDeleteStatus: 0,
  actions: {
    delete: "medl/deleteMedecin",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "nom_complet",
      label: "Nom",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "donnees_perso", params: { idmed: "id" } },
    },
    {
      prop: "no_employe",
      label: "No CHUV",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "naissance_date",
      label: "Date de naissance",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "pays",
      label: "Nationalité",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "email_chuv",
      label: "Email CHUV",
      typefiltre: "autocompl",
      visible: true,
      email: true,
    },
    {
      prop: "tel_chuv",
      label: "Tél. CHUV",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "email_prive",
      label: "Email privé",
      typefiltre: "autocompl",
      visible: true,
      email: true,
    },
    {
      prop: "tel_prive",
      label: "Tél. privé",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "mobile_prive",
      label: "Mobile privé",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "adresse",
      label: "Adresse",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "localite",
      label: "Localité",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "adressepays",
      label: "Pays",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "lastcontrat_service",
      label: "Service",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "lastcontrat_poste",
      label: "Poste",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "lastcontrat_taux",
      label: "Taux",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "lastcontrat_type",
      label: "Type de contrat",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "lastcontrat_debutdate",
      label: "Debut",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "lastcontrat_fincalc",
      label: "Fin",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "cv",
      label: "CV",
      typefiltre: "checkdoc",
      visible: true,
      liendoc: true,
      docfolder: "/rhfiles/cv/",
    },
  ],
};

const mutations = {
  setMedecins(state, medecins) {
    state.medecins = medecins;
  },
  setMedecin(state, medecin) {
    state.medecin = medecin;
  },
  resetMedecin(state) {
    state.medecin = {
      id: "",
      nom: "",
      prenom: "",
      naissance_date: null,
      no_employe: "",
      id_pays: "",
      email_prive: "",
      email_chuv: "",
      tel_prive: "",
      mobile_prive: "",
      tel_chuv: "",
      ad_rue1: "",
      ad_rue2: "",
      ad_cp: "",
      ad_ville: "",
      ad_id_pays: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setMedecinsLoadStatus(state, status) {
    state.medecinsLoadStatus = status;
  },
  setMedecinLoadStatus(state, status) {
    state.medecinLoadStatus = status;
  },
  setMedecinCreateStatus(state, status) {
    state.medecinCreateStatus = status;
  },
  setMedecinLastCreatedId(state, idmed) {
    state.medecinLastCreatedId = idmed;
  },
  setMedecinUpdateStatus(state, status) {
    state.medecinUpdateStatus = status;
  },
  setMedecinDeleteStatus(state, status) {
    state.medecinDeleteStatus = status;
  },
  MedecinRemoveItem(state, idMed) {
    state.medecins = state.medecins.filter((med) => med.id != idMed);
  },
  setMedecinPhoto(state, fileNom) {
    state.medecin.photo = fileNom;
  },
  setMedecinPhotoUploadStatus(state, status) {
    state.medecinPhotoUploadStatus = status;
  },
  setMedecinPhotoDeleteStatus(state, status) {
    state.medecinPhotoDeleteStatus = status;
  },
  setMedecinCV(state, fileNom) {
    state.medecin.cv = fileNom;
  },
  setMedecinCvUploadStatus(state, status) {
    state.medecinCvUploadStatus = status;
  },
  setMedecinCvDeleteStatus(state, status) {
    state.medecinCvDeleteStatus = status;
  },
};

const actions = {
  // charge l'ensemble des médecins
  loadMedecins({ commit, rootGetters }) {
    commit("setMedecinsLoadStatus", 1);
    medecinAPI
      .medecinIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
          // ajoute la colonne nodelete (pour que seul un utilisateur avec les droits admin puisse supprimer un médecin)
          o.nodelete = !rootGetters["auth/isAdmin"];
        });
        commit("setMedecins", response.data);
        commit("setMedecinsLoadStatus", 2);
      })
      .catch(function () {
        commit("setMedecins", []);
        commit("setMedecinsLoadStatus", 3);
      });
  },
  // charge 1 médecin
  loadMedecin({ commit }, idMed) {
    commit("setMedecinLoadStatus", 1);
    medecinAPI
      .medecinShow(idMed)
      .then(function (response) {
        commit("setMedecin", response.data);
        commit("setMedecinLoadStatus", 2);
      })
      .catch(function () {
        commit("setMedecin", {});
        commit("setMedecinLoadStatus", 3);
      });
  },
  // Créer un nouveau médecin :
  // met les données du médecin créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createMedecin({ commit, dispatch }, data) {
    commit("setMedecinCreateStatus", 1);

    medecinAPI
      .medecinCreate(
        data.no_employe,
        data.nom,
        data.prenom,
        data.naissance_date,
        data.id_pays,
        data.email_prive,
        data.email_chuv,
        data.tel_prive,
        data.mobile_prive,
        data.tel_chuv,
        data.ad_rue1,
        data.ad_rue2,
        data.ad_cp,
        data.ad_ville,
        data.ad_id_pays
      )
      .then(function (response) {
        dispatch("loadMedecins");
        commit("setMedecinCreateStatus", 2);
        commit("setMedecinLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setMedecinCreateStatus", 3);
        commit("setMedecinLastCreatedId", 0);
      });
  },
  // Met à jour les données personnelles d'un médecin
  updateMedecin({ commit, dispatch }, data) {
    commit("setMedecinUpdateStatus", 1);
    medecinAPI
      .medecinUpdate(
        data.id,
        data.no_employe,
        data.nom,
        data.prenom,
        data.naissance_date,
        data.id_pays,
        data.email_prive,
        data.email_chuv,
        data.tel_prive,
        data.mobile_prive,
        data.tel_chuv,
        data.ad_rue1,
        data.ad_rue2,
        data.ad_cp,
        data.ad_ville,
        data.ad_id_pays
      )
      .then(function (response) {
        dispatch("loadMedecins");
        commit("setMedecin", response.data);
        commit("setMedecinUpdateStatus", 2);
        // recharge toutes leur autres tables avec des enregistrements liés (maj le nom)
        dispatch("contl/loadContrats", null, { root: true });
        dispatch("fpg/loadFpostgrads", null, { root: true });
        dispatch("fc/loadFcontinues", null, { root: true });
        dispatch("hcm/loadHcactivitemedecins", null, { root: true });
        dispatch("tut/loadTuteurs", null, { root: true });
        dispatch("fpr/loadFpregrads", null, { root: true });
        dispatch("th/loadTheses", null, { root: true });
        dispatch("pc/loadParcourscliniques", null, { root: true });
      })
      .catch(function () {
        commit("setMedecinUpdateStatus", 3);
      });
  },
  // supprime 1 médecin
  deleteMedecin({ commit, dispatch }, idMed) {
    commit("setMedecinDeleteStatus", 1);
    medecinAPI
      .medecinDelete(idMed)
      .then(function () {
        commit("setMedecinDeleteStatus", 2);
        dispatch("loadMedecins");
        // recharge toutes leur autres tables avec des enregistrements liés
        dispatch("contl/loadContrats", null, { root: true });
        dispatch("fpg/loadFpostgrads", null, { root: true });
        dispatch("fc/loadFcontinues", null, { root: true });
        dispatch("hcm/loadHcactivitemedecins", null, { root: true });
        dispatch("tut/loadTuteurs", null, { root: true });
        dispatch("eva/loadEvaluations", null, { root: true });
        dispatch("fpr/loadFpregrads", null, { root: true });
        dispatch("th/loadTheses", null, { root: true });
        dispatch("pc/loadParcourscliniques", null, { root: true });
      })
      .catch(function () {
        commit("setMedecinDeleteStatus", 3);
      });
  },
  // upload la photo de profil du médecin et ajoute le nom du fichier au champ photo de la table médecin
  uploadMedecinPhoto({ commit }, data) {
    commit("setMedecinPhotoUploadStatus", 1);
    medecinAPI
      .medecinUploadPhoto(data.file, data.id)
      .then(function (response) {
        commit("setMedecinPhoto", response.data);
        commit("setMedecinPhotoUploadStatus", 2);
      })
      .catch(function () {
        commit("setMedecinPhotoUploadStatus", 3);
      });
  },
  // supprime la photo de profil du médecin et la remplace par le placeholder
  deleteMedecinPhoto({ commit }, data) {
    commit("setMedecinPhotoDeleteStatus", 1);
    medecinAPI
      .medecinDeletePhoto(data.id, data.placeholder)
      .then(function () {
        commit("setMedecinPhoto", data.placeholder);
        commit("setMedecinPhotoDeleteStatus", 2);
      })
      .catch(function () {
        commit("setMedecinPhotoDeleteStatus", 3);
      });
  },
  // upload le CV du médecin et ajoute le nom du fichier au champ cv de la table médecin
  uploadMedecinCV({ commit, dispatch }, data) {
    commit("setMedecinCvUploadStatus", 1);
    medecinAPI
      .medecinUploadCV(data.file, data.id)
      .then(function (response) {
        commit("setMedecinCV", response.data);
        commit("setMedecinCvUploadStatus", 2);
        dispatch("loadMedecins");
      })
      .catch(function () {
        commit("setMedecinCvUploadStatus", 3);
      });
  },
  // supprime le cv du médecin
  deleteMedecinCV({ commit, dispatch }, idMed) {
    commit("setMedecinCvDeleteStatus", 1);
    medecinAPI
      .medecinDeleteCV(idMed)
      .then(function () {
        commit("setMedecinCV", null);
        commit("setMedecinCvDeleteStatus", 2);
        dispatch("loadMedecins");
      })
      .catch(function () {
        commit("setMedecinCvDeleteStatus", 3);
      });
  },
};

const getters = {
  // array de nom+prenom+naissance_date pour vérifier si médecin déjà dans la BD
  MedsInBD: (state) => {
    return state.medecins.map((med) => [
      med.id,
      strNormalize(med.nom),
      strNormalize(med.prenom),
      med.naissance_date,
    ]);
  },
  // array des no_employe pour vérifier si déjà dans la BD
  nosChuv: (state) => {
    var Nos = state.medecins.map((med) => [med.id, med.no_employe]);
    Nos = Nos.filter((no) => no[1]); // enlève les valeurs null ou vides
    return Nos;
  },
  medecinSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....]
    const medListe = state.medecins.map((obj) => {
      return {
        value: obj.id,
        label: obj.nom_complet,
        lastposte: obj.lastcontrat_poste,
      };
    });
    // tri sur noms asc
    return medListe.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
