import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  theseIndex: function () {
    return API.apiClient.get("/theses");
  },
  // retourne un item (ID)
  theseShow: function (id) {
    return API.apiClient.get("/theses/" + id);
  },
  // pour créer un nouvel item
  theseCreate: function (id_med, id_fac, id_statut, titre, annee) {
    return API.apiClient.post("/theses", {
      id_med: id_med,
      id_fac: id_fac,
      id_statut: id_statut,
      titre: titre,
      annee: annee,
    });
  },
  // met à jour un item
  theseUpdate: function (id, id_med, id_fac, id_statut, titre, annee) {
    return API.apiClient.put("/theses/" + id, {
      id_med: id_med,
      id_fac: id_fac,
      id_statut: id_statut,
      titre: titre,
      annee: annee,
    });
  },
  // supprime un item
  theseDelete: function (thId) {
    return API.apiClient.delete("/theses/" + thId);
  },
};
