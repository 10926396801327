import UserService from "@/services/UserService";
import {
  //deleteObjInArray,
  //changeObjValInArray,
  triParColonne,
  objGetNested,
  //addColsFilterlist,
} from "@/fonctions";

const state = {
  // TABLES
  cols: [],
  items: [],
  tableNom: null,
  userTablesPrefs: {},
  usertablesprefsLoadStatus: 0,
  usertablesprefsUpdateStatus: 0,
  // Filtre des colonnes
  colsVisible: [],
  // Filtre des lignes
  itemsFiltre: [],
  // ligne par page
  linePage: 20,
  // tri
  triCol: "noCol",
  triSens: "noSens",
  triCount: 0,
  // prefs de l'user
  isMounted: false,
  // FORMULAIRES
  // affichage de sous-formulaires dans les boîte de dialogue
  dialogShowFacCreate: false,
  dialogShowFcTitreCreate: false,
  dialogShowFcAdd: false,
  dialogShowHcaTitreCreate: false,
  dialogShowFMHCreate: false,
};

const mutations = {
  // TABLES
  setTableNom(state, tablenom) {
    state.tableNom = tablenom;
  },
  setLinePage(state, linePage) {
    state.linePage = linePage;
  },
  resetCols(state) {
    state.cols = [];
  },
  setCols(state, colsInput) {
    let cols = colsInput ? colsInput : state.cols;
    // visibilité des colonnes : récupère les préfs de l'user sur mounted si définies
    const colsvis = state.colsVisible;
    if (colsvis.length > 0 && !state.isMounted) {
      cols.map((col) => (col.visible = colsvis.includes(col.prop)));
      state.isMounted = true;
    }
    // récupère le array des colonnes sur lesquelles un filtre est appliqué
    const fcols = state.itemsFiltre.map((f) => f.col);
    // indique si un filtre est appliqué sur la colonne => mets le nom de la colonne en jaune
    // si aucun item filtré => ne tag aucune colonne comme filtrés (le bouton effacé tous les filtres ne s'affiche pas)
    const isFiltred = state.items.filter((i) => !i.visible).length > 0;
    cols.map((col) => (col.isFiltered = isFiltred && fcols.includes(col.prop)));
    state.cols = cols;
  },
  setItems(state, itemsInput) {
    let items = itemsInput ? itemsInput : state.items;
    /***       TRI     ***/
    // règles de tri, si pas de tri applique le tri par défaut (sur colonne tri_defaut)
    const col = state.triCol;
    const sens = state.triSens;
    if (col != "noCol" && sens != "noSens") {
      triParColonne(items, col, sens);
    } else {
      // tri par défaut
      items.sort((a, b) => {
        a = a.tri_defaut;
        b = b.tri_defaut;
        return a - b;
      });
    }
    /***    FILTRES   ***/
    items.map((i) => {
      // Ajoute à chaque item la propriété 'visible'
      i.visible = true;
      // Passe la propriétié visible de chaque item à null, si item exclu par au moins 1 filtre
      state.itemsFiltre.forEach((f) => {
        // valeur de l'item pour cette colonne
        const itemColVal = objGetNested(i, f.col.split("."));
        if (["autocompl", "jdate", "mdate"].includes(f.typef)) {
          if ([null, undefined, ""].includes(itemColVal)) {
            if (!f.nullchecked) {
              i.visible = false;
            }
          } else if (f.typef == "autocompl") {
            if (f.valf) {
              if (
                itemColVal
                  .toString()
                  .toLowerCase()
                  .indexOf(f.valf.toString().toLowerCase()) == -1
              ) {
                // false si le string recheché n'est pas dans le texte et quelque soit la case
                i.visible = false;
              }
            } else {
              i.visible = false;
            }
          } else if (["jdate", "mdate"].includes(f.typef)) {
            if (f.valf.length == 2) {
              // enlève les heures des dates de maj
              const ymd = itemColVal.substring(0, 10);
              if (!(ymd >= f.valf[0] && ymd <= f.valf[1])) {
                i.visible = false;
              }
            } else {
              i.visible = false;
            }
          }
        } else if (["multichoix", "checkbool", "checkdoc"].includes(f.typef)) {
          if (itemColVal == null) {
            // == null (retourne null et undefined)
            if (!f.valf.includes(null)) {
              i.visible = false;
            }
          } else {
            if (f.typef == "checkdoc") {
              if (f.valf.filter((v) => v).length == 0) {
                i.visible = false;
              }
            } else {
              // si items pas dans la liste visible => false
              if (!f.valf.includes(itemColVal)) {
                i.visible = false;
              }
            }
          }
        }
      });
    });
    state.items = items;
  },
  // définit la colonne et le sens du tri
  triChange(state, colKey) {
    if (state.triCount == 0) {
      state.triSens = "ascending";
      state.triCount++;
    } else if (state.triCount == 1) {
      state.triSens = "descending";
      state.triCount++;
    } else if (state.triCount == 2) {
      state.triSens = "noSens";
      state.triCount = 0;
    }
    state.triCol = colKey;
  },
  // Sur changement d'un filtre sur une table récupère dans l'array itemsFiltre les valeurs du filtre
  // si filtre effacé, le supprime de l'array itemsFiltre
  //{col:"prop_col", typef:"autocompl | multichoix | jdate | checkdoc | checkbool | mdate", valf: array | string, nullchecked: true | false }
  // cette valeur est reprise dans setItems pour définir la propriété visible de chaque item
  filtreChange(state, f) {
    // enlève le non de la mutation
    delete f.type;
    // récupère l'index du filtre dans l'array des filtres
    const idx = state.itemsFiltre.findIndex((i) => i.col == f.col);
    // check si filtre actif
    const isFiltre = f.valf.length > 0 || !!f.nullchecked;
    if (idx > -1) {
      // si le filtre est dans l'array
      if (isFiltre) {
        // si actif => le remplace
        state.itemsFiltre[idx] = f;
      } else {
        // si non le supprime
        state.itemsFiltre = state.itemsFiltre.filter((i) => i.col != f.col);
      }
    } else if (isFiltre) {
      // si non l'ajoute si actif
      state.itemsFiltre.push(f);
    }
  },
  // Efface tous les filtres sur les colonnes
  clearAllFilters(state) {
    state.itemsFiltre = [];
  },
  // Efface toutes les prefs de l'user$
  clearAllPrefs(state) {
    state.colsVisible = [];
    state.itemsFiltre = [];
    state.triCol = "noCol";
    state.triSens = "noSens";
    state.triCount = 0;
    state.linePage = 20;
  },
  // table : USER PREFS
  setUserTablesPrefs(state, tprefs) {
    // commit après le login dans comp layout/AppLoaddata.vue
    state.userTablesPrefs = tprefs ? tprefs : {};
  },
  setUserTablesPrefsLoadStatus(state, status) {
    state.usertablesprefsLoadStatus = status;
  },
  setUserTablesPrefsUpdateStatus(state, status) {
    state.usertablesprefsUpdateStatus = status;
  },
  // isMounted pour déterminer si il faut charger les prefs de l'user
  setIsMounted(state, bool) {
    state.isMounted = bool;
  },
  //réccupère les prefs de l'user de la table users et si n'existent pas crée les prefs par défaut
  setUserCurrentTablePrefs(state) {
    const tnom = state.tableNom;
    const prefs = objGetNested(state.userTablesPrefs, [tnom]);
    // si prefs pas définies pour cette table : récupère les valeurs
    if (prefs) {
      state.colsVisible = prefs.colsvisible;
      state.itemsFiltre = prefs.itemsfiltre;
      state.triCol = prefs.tri.col;
      state.triSens = prefs.tri.sens;
      state.linePage = prefs.linepage;
    }
  },
  // sur modification des prefs (watch dans AppTable.vue) maj la table actuelle dans state.userTablesPrefs
  // avant de maj les prefs dans la table user (action : majUserTablesPrefs)
  majUserCurrentTablePrefs(state) {
    const prefs = {
      colsvisible: state.cols.filter((c) => c.visible).map((c) => c.prop),
      itemsfiltre: state.itemsFiltre,
      tri: { col: state.triCol, sens: state.triSens },
      linepage: state.linePage,
    };
    state.userTablesPrefs[state.tableNom] = prefs;
  },
  // FORMULAIRES
  setDialogShowFacCreate(state, bool) {
    state.dialogShowFacCreate = bool;
  },
  setDialogShowFcTitreCreate(state, bool) {
    state.dialogShowFcTitreCreate = bool;
  },
  setDialogShowFcAdd(state, bool) {
    state.dialogShowFcAdd = bool;
  },
  setDialogShowHcaTitreCreate(state, bool) {
    state.dialogShowHcaTitreCreate = bool;
  },
  setDialogShowFMHCreate(state, bool) {
    state.dialogShowFMHCreate = bool;
  },
};

const actions = {
  loadUsertablesprefs({ commit }) {
    commit("setUserTablesPrefsLoadStatus", 1);
    UserService.getUserPrefsTables()
      .then(function (reponse) {
        const rep = reponse.data.data.prefs_tables
          ? JSON.parse(reponse.data.data.prefs_tables)
          : {};
        commit("setUserTablesPrefs", rep);
        commit("setUserTablesPrefsLoadStatus", 2);
      })
      .catch(function () {
        commit("setUserTablesPrefsLoadStatus", 3);
      });
  },
  // appelé dans compenents/rh/ui/table/AppTable.vue
  majUserTablesPrefs({ commit, state }) {
    commit("majUserCurrentTablePrefs");
    commit("setUserTablesPrefsUpdateStatus", 1);
    const prefsTablesStr = JSON.stringify(state.userTablesPrefs);
    UserService.majUserPrefsTables(prefsTablesStr)
      .then(function () {
        commit("setUserTablesPrefsUpdateStatus", 2);
      })
      .catch(function () {
        commit("setUserTablesPrefsUpdateStatus", 3);
      });
  },
};

const getters = {
  itemsVisibleNbre: (state) => {
    return state.items.filter((i) => i.visible).length;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
