import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  tuteurIndex: function () {
    return API.apiClient.get("/tuteurs");
  },
  // retourne un item (ID)
  tuteurShow: function (tutId) {
    return API.apiClient.get("/tuteurs/" + tutId);
  },
  // pour créer un nouvel item
  tuteurCreate: function (
    id_pupille,
    id_tuteur,
    debut_date,
    fin_date,
    prochain_eval_date
  ) {
    return API.apiClient.post("/tuteurs", {
      id_pupille: id_pupille,
      id_tuteur: id_tuteur,
      debut_date: debut_date,
      fin_date: fin_date,
      prochain_eval_date: prochain_eval_date,
    });
  },
  // met à jour un item
  tuteurUpdate: function (
    id,
    id_pupille,
    id_tuteur,
    debut_date,
    fin_date,
    prochain_eval_date
  ) {
    return API.apiClient.put("/tuteurs/" + id, {
      id_pupille: id_pupille,
      id_tuteur: id_tuteur,
      debut_date: debut_date,
      fin_date: fin_date,
      prochain_eval_date: prochain_eval_date,
    });
  },
  // supprime un item
  tuteurDelete: function (tutId) {
    return API.apiClient.delete("/tuteurs/" + tutId);
  },
};
