import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  medecinIndex: function () {
    return API.apiClient.get("/medecins");
  },
  // retourne un item (ID)
  medecinShow: function (medId) {
    return API.apiClient.get("/medecins/" + medId);
  },
  // pour créer un nouvel item
  medecinCreate: function (
    no_employe,
    nom,
    prenom,
    naissance_date,
    id_pays,
    email_prive,
    email_chuv,
    tel_prive,
    mobile_prive,
    tel_chuv,
    ad_rue1,
    ad_rue2,
    ad_cp,
    ad_ville,
    ad_id_pays
  ) {
    return API.apiClient.post("/medecins", {
      no_employe: no_employe,
      nom: nom,
      prenom: prenom,
      naissance_date: naissance_date,
      id_pays: id_pays,
      email_prive: email_prive,
      email_chuv: email_chuv,
      tel_prive: tel_prive,
      mobile_prive: mobile_prive,
      tel_chuv: tel_chuv,
      ad_rue1: ad_rue1,
      ad_rue2: ad_rue2,
      ad_cp: ad_cp,
      ad_ville: ad_ville,
      ad_id_pays: ad_id_pays,
    });
  },
  // met à jour un item
  medecinUpdate: function (
    id,
    no_employe,
    nom,
    prenom,
    naissance_date,
    id_pays,
    email_prive,
    email_chuv,
    tel_prive,
    mobile_prive,
    tel_chuv,
    ad_rue1,
    ad_rue2,
    ad_cp,
    ad_ville,
    ad_id_pays
  ) {
    return API.apiClient.put("/medecins/" + id, {
      no_employe: no_employe,
      nom: nom,
      prenom: prenom,
      naissance_date: naissance_date,
      id_pays: id_pays,
      email_prive: email_prive,
      email_chuv: email_chuv,
      tel_prive: tel_prive,
      mobile_prive: mobile_prive,
      tel_chuv: tel_chuv,
      ad_rue1: ad_rue1,
      ad_rue2: ad_rue2,
      ad_cp: ad_cp,
      ad_ville: ad_ville,
      ad_id_pays: ad_id_pays,
    });
  },
  // supprime un item
  medecinDelete: function (medId) {
    return API.apiClient.delete("/medecins/" + medId);
  },
  // charge la photo de profil
  medecinUploadPhoto: function (file, medid) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id_med", medid);

    return API.apiClient.post("/medecin-uploadprofil", formData);
  },
  // supprime la photo de profil d'un médecin
  medecinDeletePhoto: function (medid, placeholder) {
    const formData = new FormData();
    formData.append("id_med", medid);
    formData.append("placeholder", placeholder);

    return API.apiClient.post("/medecin-supprimeprofil", formData);
  },
  // charge le CV
  medecinUploadCV: function (file, medid) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id_med", medid);

    return API.apiClient.post("/medecin-uploadcv", formData);
  },
  // supprime le CV
  medecinDeleteCV: function (medid) {
    const formData = new FormData();
    formData.append("id_med", medid);
    return API.apiClient.post("/medecin-supprimecv", formData);
  },
};
