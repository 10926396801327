import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  faculteIndex: function () {
    return API.apiClient.get("/facultes");
  },
  // retourne un item (ID)
  faculteShow: function (id) {
    return API.apiClient.get("/facultes/" + id);
  },
  // pour créer un nouvel item
  faculteCreate: function (id_pays, fac) {
    return API.apiClient.post("/facultes", {
      id_pays: id_pays,
      fac: fac,
    });
  },
  // met à jour un item
  faculteUpdate: function (id, id_pays, fac) {
    return API.apiClient.put("/facultes/" + id, {
      id_pays: id_pays,
      fac: fac,
    });
  },
  // supprime un item
  faculteDelete: function (facId) {
    return API.apiClient.delete("/facultes/" + facId);
  },
};
