import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/reset.css"; /*https://stackoverflow.com/questions/52050877/element-ui-font-family-differencies-between-components*/
import locale from "element-ui/lib/locale/lang/fr";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/assets/css/urgrh.css";

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
