// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import hcactiviteAPI from "@/services/rh/hcactivite.js";
import { strNormalize, toLocaleDateStr } from "@/fonctions";

const state = {
  hcactivites: [],
  hcactivitesLoadStatus: 0,
  hcactivite: {},
  hcactiviteLoadStatus: 0,
  hcactiviteCreateStatus: 0,
  hcactiviteLastCreatedId: 0,
  hcactiviteUpdateStatus: 0,
  hcactiviteDeleteStatus: 0,
  actions: {
    delete: "hc/deleteHcactivite",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "hcactivite_descri",
      label: "Activité hors-clinique",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "categorie",
      label: "Type",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "occurences_nbre",
      label: "Nbre d'utilisations",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "created_at",
      label: "Créé le",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "updated_at",
      label: "Dernière maj",
      typefiltre: "jdate",
      visible: true,
    },
  ],
};

const mutations = {
  setHcactivites(state, hcactivites) {
    state.hcactivites = hcactivites;
  },
  setHcactivite(state, hcactivite) {
    state.hcactivite = hcactivite;
  },
  resetHcactivite(state, idcat) {
    state.hcactivite = { id: "", id_hc_cat: idcat, hcactivite_descri: "" };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setHcactivitesLoadStatus(state, status) {
    state.hcactivitesLoadStatus = status;
  },
  setHcactiviteLoadStatus(state, status) {
    state.hcactiviteLoadStatus = status;
  },
  setHcactiviteCreateStatus(state, status) {
    state.hcactiviteCreateStatus = status;
  },
  setHcactiviteLastCreatedId(state, idhc) {
    state.hcactiviteLastCreatedId = idhc;
  },
  setHcactiviteUpdateStatus(state, status) {
    state.hcactiviteUpdateStatus = status;
  },
  setHcactiviteDeleteStatus(state, status) {
    state.hcactiviteDeleteStatus = status;
  },
  hcactiviteRemoveItem(state, idhc) {
    state.hcactivites = state.hcactivites.filter((act) => act.id != idhc);
  },
};

const actions = {
  loadHcactivites({ commit }) {
    commit("setHcactivitesLoadStatus", 1);
    hcactiviteAPI
      .hcactiviteIndex()
      .then(function (response) {
        const colDates = ["created_at", "updated_at"];
        response.data.map((o, idx) => {
          colDates.forEach((d) => (o[d] = toLocaleDateStr(o[d])));
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
          // ajoute la colonne nodelete (pour empêcher d'effacer les élements utilisés dans des tables)
          o.nodelete = o.occurences_nbre > 0;
        });
        commit("setHcactivites", response.data);
        commit("setHcactivitesLoadStatus", 2);
      })
      .catch(function () {
        commit("setHcactivites", []);
        commit("setHcactivitesLoadStatus", 3);
      });
  },
  // charge 1 hcactivite
  loadHcactivite({ commit }, idhc) {
    commit("setHcactiviteLoadStatus", 1);
    hcactiviteAPI
      .hcactiviteShow(idhc)
      .then(function (response) {
        const colDates = ["created_at", "updated_at"];
        colDates.forEach(
          (d) => (response.data[d] = toLocaleDateStr(response.data[d]))
        );
        commit("setHcactivite", response.data);
        commit("setHcactiviteLoadStatus", 2);
      })
      .catch(function () {
        commit("setHcactivite", {});
        commit("setHcactiviteLoadStatus", 3);
      });
  },
  // Créer un nouveau hcactivite :
  // met les données du hcactivite créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createHcactivite({ commit, dispatch }, data) {
    commit("setHcactiviteCreateStatus", 1);
    hcactiviteAPI
      .hcactiviteCreate(data.id_hc_cat, data.hcactivite_descri)
      .then(function (response) {
        dispatch("loadHcactivites");
        commit("setHcactiviteCreateStatus", 2);
        commit("setHcactiviteLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setHcactiviteCreateStatus", 3);
      });
  },
  // Met à jour les données d'un hcactivite
  updateHcactivite({ commit, dispatch }, data) {
    commit("setHcactiviteUpdateStatus", 1);
    hcactiviteAPI
      .hcactiviteUpdate(data.id, data.id_hc_cat, data.hcactivite_descri)
      .then(function (response) {
        dispatch("loadHcactivites");
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setHcactivite", response.data);
        commit("setHcactiviteUpdateStatus", 2);
        // reload aussi la table hcactivitemedecin => maj
        dispatch("hcm/loadHcactivitemedecins", null, { root: true });
      })
      .catch(function () {
        commit("setHcactiviteUpdateStatus", 3);
      });
  },
  // supprime 1 hcactivite
  deleteHcactivite({ commit, dispatch }, idhc) {
    commit("setHcactiviteDeleteStatus", 1);
    hcactiviteAPI
      .hcactiviteDelete(idhc)
      .then(function () {
        commit("setHcactiviteDeleteStatus", 2);
        dispatch("loadHcactivites");
      })
      .catch(function () {
        commit("setHcactiviteDeleteStatus", 3);
      });
  },
};

const getters = {
  hcactivitesInBD: (state) => {
    // dans form create pour vérifier si l'activité existe déjà dans la BD
    // nom de l'activité en minuscule et sans espace et sans accents
    return state.hcactivites.map((f) => [
      f.id,
      f.id_hc_cat,
      strNormalize(f.hcactivite_descri),
    ]);
  },
  hcactiviteSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....] .filter( obj => obj.fcontinue_opt == 1)
    return state.hcactivites.map((obj) => {
      return {
        value: obj.id,
        label: obj.hcactivite_descri,
        type: obj.id_hc_cat,
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
