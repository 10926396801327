// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import permisAPI from "@/services/rh/permis.js";

const state = {
  permis: [],
  permisLoadStatus: 0,
};

const mutations = {
  setPermis(state, permis) {
    state.permis = permis;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setPermisLoadStatus(state, permis) {
    state.permisLoadStatus = permis;
  },
};

const actions = {
  loadPermis({ commit }) {
    commit("setPermisLoadStatus", 1);
    permisAPI
      .permisIndex()
      .then(function (response) {
        commit("setPermis", response.data);
        commit("setPermisLoadStatus", 2);
      })
      .catch(function () {
        commit("setPermis", []);
        commit("setPermisLoadStatus", 3);
      });
  },
};

const getters = {
  permisSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....]
    return state.permis.map((obj) => {
      return { value: obj.id, label: obj.permis + " - " + obj.permis_descri };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
