import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  parcourscliniqueIndex: function () {
    return API.apiClient.get("/parcourscliniques");
  },
  // retourne un item (ID)
  parcourscliniqueShow: function (pcId) {
    return API.apiClient.get("/parcourscliniques/" + pcId);
  },
  // pour créer un nouvel item
  parcourscliniqueCreate: function (
    id_med,
    id_activite,
    id_taux,
    debut_date,
    fin_date
  ) {
    return API.apiClient.post("/parcourscliniques", {
      id_med: id_med,
      id_activite: id_activite,
      id_taux: id_taux,
      debut_date: debut_date,
      fin_date: fin_date,
    });
  },
  // met à jour un item
  parcourscliniqueUpdate: function (
    id,
    id_med,
    id_activite,
    id_taux,
    debut_date,
    fin_date
  ) {
    return API.apiClient.put("/parcourscliniques/" + id, {
      id_med: id_med,
      id_activite: id_activite,
      id_taux: id_taux,
      debut_date: debut_date,
      fin_date: fin_date,
    });
  },
  // supprime un item
  parcourscliniqueDelete: function (pcId) {
    return API.apiClient.delete("/parcourscliniques/" + pcId);
  },
};
