// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import contratAPI from "@/services/rh/contrat.js";

const state = {
  contrats: [],
  contratsLoadStatus: 0,
  contrat: {},
  contratLoadStatus: 0,
  contratCreateStatus: 0,
  contratLastCreatedId: 0,
  contratUpdateStatus: 0,
  contratDeleteStatus: 0,
  actions: {
    delete: "contl/deleteContrat",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_contrats", params: { idmed: "id_med" } },
    },
    {
      prop: "poste",
      label: "Poste",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "service",
      label: "Service",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "type",
      label: "Type",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "taux",
      label: "Taux",
      typefiltre: "multichoix",
      visible: true,
    },
    /*{
      prop: "permis",
      label: "Permis",
      typefiltre: "multichoix",
      visible: true,
    },*/
    {
      prop: "coromu",
      label: "AROMU",
      typefiltre: "checkbool",
      visible: true,
      bool: true,
    },
    {
      prop: "activite_xurg",
      label: "Activités xurg",
      typefiltre: "checkbool",
      visible: true,
      bool: true,
    },
    {
      prop: "debut_date",
      label: "Debut",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "fin_calc",
      label: "Fin dernier PF / Fin contrat",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "motif",
      label: "Motif fin",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "depart_prev_mois",
      label: "Départ envisagé",
      typefiltre: "mdate",
      visible: true,
    },
    {
      prop: "depart_prev_proj",
      label: "Projets",
      typefiltre: "autocompl",
      visible: true,
    },
  ],
};

const getters = {
  ContsInBD: (state) => {
    return state.contrats.map((cont) => [
      cont.id,
      cont.id_med,
      cont.debut_date,
    ]);
  },
};

const mutations = {
  setContrats(state, contrats) {
    state.contrats = contrats;
  },
  setContrat(state, contrat) {
    state.contrat = contrat;
  },
  resetContrat(state, idmed) {
    state.contrat = {
      id: "",
      id_med: idmed,
      id_service: "",
      id_poste: "",
      id_taux: "",
      id_permis: "",
      id_type: 2,
      coromu: false,
      activite_xurg: false,
      activite_xurg_prec: "",
      debut_date: "",
      fin_prevue_date: "",
      fin_effect_date: "",
      id_fin_motif: "",
      fin_motif_prec: "",
      depart_prev_mois: "",
      depart_prev_proj: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setContratsLoadStatus(state, status) {
    state.contratsLoadStatus = status;
  },
  setContratLoadStatus(state, status) {
    state.contratLoadStatus = status;
  },
  setContratCreateStatus(state, status) {
    state.contratCreateStatus = status;
  },
  setContratLastCreatedId(state, idcont) {
    state.contratLastCreatedId = idcont;
  },
  setContratUpdateStatus(state, status) {
    state.contratUpdateStatus = status;
  },
  setContratDeleteStatus(state, status) {
    state.contratDeleteStatus = status;
  },
  contratRemoveItem(state, idCont) {
    state.contrats = state.contrats.filter((cont) => cont.id != idCont);
  },
};

const actions = {
  // charge l'ensemble des contrats
  loadContrats({ commit }) {
    commit("setContratsLoadStatus", 1);
    contratAPI
      .contratIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setContrats", response.data);
        commit("setContratsLoadStatus", 2);
      })
      .catch(function () {
        commit("setContrats", []);
        commit("setContratsLoadStatus", 3);
      });
  },
  // charge 1 contrat
  loadContrat({ commit }, idCont) {
    commit("setContratLoadStatus", 1);
    contratAPI
      .contratShow(idCont)
      .then(function (response) {
        commit("setContrat", response.data);
        commit("setContratLoadStatus", 2);
      })
      .catch(function () {
        commit("setContrat", {});
        commit("setContratLoadStatus", 3);
      });
  },
  // Créer un nouveau contrat :
  // met les données du contrat créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createContrat({ commit, dispatch }, data) {
    commit("setContratCreateStatus", 1);

    contratAPI
      .contratCreate(
        data.id_med,
        data.id_service,
        data.id_poste,
        data.id_taux,
        data.id_permis,
        data.id_type,
        data.coromu,
        data.activite_xurg,
        data.activite_xurg_prec,
        data.debut_date,
        data.fin_prevue_date,
        data.fin_effect_date,
        data.id_fin_motif,
        data.fin_motif_prec,
        data.depart_prev_mois,
        data.depart_prev_proj
      )
      .then(function (response) {
        dispatch("loadContrats");
        commit("setContratCreateStatus", 2);
        commit("setContratLastCreatedId", response.data.id);
        // maj aussi dernier contrat dans la table médecin
        dispatch("medl/loadMedecins", null, { root: true });
      })
      .catch(function () {
        commit("setContratCreateStatus", 3);
      });
  },
  // Met à jour les données d'un contrat
  updateContrat({ commit, dispatch }, data) {
    commit("setContratUpdateStatus", 1);

    contratAPI
      .contratUpdate(
        data.id,
        data.id_med,
        data.id_service,
        data.id_poste,
        data.id_taux,
        data.id_permis,
        data.id_type,
        data.coromu,
        data.activite_xurg,
        data.activite_xurg_prec,
        data.debut_date,
        data.fin_prevue_date,
        data.fin_effect_date,
        data.id_fin_motif,
        data.fin_motif_prec,
        data.depart_prev_mois,
        data.depart_prev_proj
      )
      .then(function (response) {
        dispatch("loadContrats");
        commit("setContrat", response.data);
        commit("setContratUpdateStatus", 2);
        // maj aussi dernier contrat dans la table médecin
        dispatch("medl/loadMedecins", null, { root: true });
      })
      .catch(function () {
        commit("setContratUpdateStatus", 3);
      });
  },
  // supprime 1 contrat
  deleteContrat({ commit, dispatch }, idCont) {
    commit("setContratDeleteStatus", 1);
    contratAPI
      .contratDelete(idCont)
      .then(function () {
        commit("setContratDeleteStatus", 2);
        dispatch("loadContrats");
        // maj aussi dernier contrat dans la table médecin
        dispatch("medl/loadMedecins", null, { root: true });
      })
      .catch(function () {
        commit("setContratDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
