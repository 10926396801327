import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/Auth";
import user from "@/store/modules/User";

import components from "@/store/modules/rh/components";
import medecin from "@/store/modules/rh/medecin";
import contrat from "@/store/modules/rh/contrat";
import pays from "@/store/modules/rh/pays";
import contrattype from "@/store/modules/rh/contrattype";
import finmotif from "@/store/modules/rh/finmotif";
import permis from "@/store/modules/rh/permis";
import poste from "@/store/modules/rh/poste";
import service from "@/store/modules/rh/service";
import taux from "@/store/modules/rh/taux";
import fpostgrad from "@/store/modules/rh/fpostgrad";
import fpostgradcategorie from "@/store/modules/rh/fpostgradcategorie";
import fpostgradtitre from "@/store/modules/rh/fpostgradtitre";
import fpostgradsite from "@/store/modules/rh/fpostgradsite";
import formationstatut from "@/store/modules/rh/formationstatut";
import fcontinue from "@/store/modules/rh/fcontinue";
import fcontinuetitre from "@/store/modules/rh/fcontinuetitre";
import fcontinuecategorie from "@/store/modules/rh/fcontinuecategorie";
import hcactivitemedecin from "@/store/modules/rh/hcactivitemedecin";
import hcactivite from "@/store/modules/rh/hcactivite";
import hcactivitecategorie from "@/store/modules/rh/hcactivitecategorie";
import tuteur from "@/store/modules/rh/tuteur";
import evaluation from "@/store/modules/rh/evaluation";
import fpregrad from "@/store/modules/rh/fpregrad";
import these from "@/store/modules/rh/these";
import faculte from "@/store/modules/rh/faculte";
import pcactivite from "@/store/modules/rh/pcactivite";
import parcoursclinique from "@/store/modules/rh/parcoursclinique";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    user: user,
    comp: components,
    medl: medecin,
    paysl: pays,
    contl: contrat,
    contypel: contrattype,
    fmotifl: finmotif,
    permisl: permis,
    postel: poste,
    servl: service,
    tauxl: taux,
    fpg: fpostgrad,
    fpgtit: fpostgradtitre,
    fpgsit: fpostgradsite,
    fpgcat: fpostgradcategorie,
    fstatut: formationstatut,
    fc: fcontinue,
    fctit: fcontinuetitre,
    fccat: fcontinuecategorie,
    hcm: hcactivitemedecin,
    hc: hcactivite,
    hccat: hcactivitecategorie,
    tut: tuteur,
    eva: evaluation,
    fpr: fpregrad,
    th: these,
    fac: faculte,
    pca: pcactivite,
    pc: parcoursclinique,
  },
});
