// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import fcontinuetitreAPI from "@/services/rh/fcontinuetitre.js";
import { strNormalize, toLocaleDateStr } from "@/fonctions";

const state = {
  titres: [],
  titresLoadStatus: 0,
  titre: {},
  titreLoadStatus: 0,
  titreCreateStatus: 0,
  titreLastCreatedId: 0,
  titreUpdateStatus: 0,
  titreDeleteStatus: 0,
  actions: {
    delete: "fctit/deleteTitre",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "titre",
      label: "Formation continue",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "categorie",
      label: "Type",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "fmh_mig",
      label: "Checklist FMH MIG",
      typefiltre: "checkbool",
      bool: true,
      visible: true,
    },
    {
      prop: "afc_hosp",
      label: "Checklist FAI HOSP",
      typefiltre: "checkbool",
      bool: true,
      visible: true,
    },
    {
      prop: "afc_xhosp",
      label: "Checklist AFC XHOSP",
      typefiltre: "checkbool",
      bool: true,
      visible: true,
    },
    {
      prop: "occurences_nbre",
      label: "Nbre d'utilisations",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "created_at",
      label: "Créé le",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "updated_at",
      label: "Dernière maj",
      typefiltre: "jdate",
      visible: true,
    },
  ],
};

const mutations = {
  setTitres(state, titres) {
    state.titres = titres;
  },
  setTitre(state, titre) {
    state.titre = titre;
  },
  resetTitre(state, idcat) {
    state.titre = { id: "", id_fcont_cat: idcat, titre: "" };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setTitresLoadStatus(state, status) {
    state.titresLoadStatus = status;
  },
  setTitreLoadStatus(state, status) {
    state.titreLoadStatus = status;
  },
  setTitreCreateStatus(state, status) {
    state.titreCreateStatus = status;
  },
  setTitreLastCreatedId(state, idfc) {
    state.titreLastCreatedId = idfc;
  },
  setTitreUpdateStatus(state, status) {
    state.titreUpdateStatus = status;
  },
  setTitreDeleteStatus(state, status) {
    state.titreDeleteStatus = status;
  },
  titreRemoveItem(state, idfc) {
    state.titres = state.titres.filter((fc) => fc.id != idfc);
  },
};

const actions = {
  loadTitres({ commit }) {
    commit("setTitresLoadStatus", 1);
    fcontinuetitreAPI
      .titreIndex()
      .then(function (response) {
        const colDates = ["created_at", "updated_at"];
        response.data.map((o, idx) => {
          colDates.forEach((d) => (o[d] = toLocaleDateStr(o[d])));
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
          // ajoute la colonne nodelete (pour empêcher d'effacer les élements utilisés dans des tables ou utilisés dans une checklist)
          o.nodelete = o.occurences_nbre > 0 || o.fpg_checklist != null;
          // ajoute la colonne noedit (pour empêcher les éléments faisant partie d'une checklist d'être édités)
          o.noedit = o.fpg_checklist != null;
        });
        commit("setTitres", response.data);
        commit("setTitresLoadStatus", 2);
      })
      .catch(function () {
        commit("setTitres", []);
        commit("setTitresLoadStatus", 3);
      });
  },
  // charge 1 titre de fcont
  loadTitre({ commit }, idfc) {
    commit("setTitreLoadStatus", 1);
    fcontinuetitreAPI
      .titreShow(idfc)
      .then(function (response) {
        const colDates = ["created_at", "updated_at"];
        colDates.forEach(
          (d) => (response.data[d] = toLocaleDateStr(response.data[d]))
        );
        commit("setTitre", response.data);
        commit("setTitreLoadStatus", 2);
      })
      .catch(function () {
        commit("setTitre", {});
        commit("setTitreLoadStatus", 3);
      });
  },
  // Créer un nouveau titre :
  // met les données du titre créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createTitre({ commit, dispatch }, data) {
    commit("setTitreCreateStatus", 1);
    fcontinuetitreAPI
      .titreCreate(data.id_fcont_cat, data.titre)
      .then(function (response) {
        dispatch("loadTitres");
        commit("setTitreCreateStatus", 2);
        commit("setTitreLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setTitreCreateStatus", 3);
      });
  },
  // Met à jour les données d'un titre
  updateTitre({ commit, dispatch }, data) {
    commit("setTitreUpdateStatus", 1);
    fcontinuetitreAPI
      .titreUpdate(data.id, data.id_fcont_cat, data.titre)
      .then(function (response) {
        dispatch("loadTitres");
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setTitre", response.data);
        commit("setTitreUpdateStatus", 2);
        // reload aussi la table des fcontinues (attribuées aux médecins) => maj
        dispatch("fc/loadFcontinues", null, { root: true });
      })
      .catch(function () {
        commit("setTitreUpdateStatus", 3);
      });
  },
  // supprime 1 titre
  deleteTitre({ commit, dispatch }, idfc) {
    commit("setTitreDeleteStatus", 1);
    fcontinuetitreAPI
      .titreDelete(idfc)
      .then(function () {
        commit("setTitreDeleteStatus", 2);
        dispatch("loadTitres");
      })
      .catch(function () {
        commit("setTitreDeleteStatus", 3);
      });
  },
};

const getters = {
  titresInBD: (state) => {
    // dans form create pour vérifier si le titre existe déjà dans la BD
    // nom de la formation en minuscule et sans espace et sans accents
    return state.titres.map((f) => [
      f.id,
      f.id_fcont_cat,
      strNormalize(f.titre),
    ]);
  },
  titreSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....] .filter( obj => obj.fcontinue_opt == 1)
    return state.titres.map((obj) => {
      return { value: obj.id, label: obj.titre, type: obj.id_fcont_cat };
    });
  },
  IdTitresInClFpg: (state) => {
    // array des id des titres qui sont dans les checklists des Fpostgrads
    return state.titres
      .filter((obj) => !!obj.fpg_checklist)
      .map((obj) => {
        return obj.id;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
