// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import evaluationAPI from "@/services/rh/evaluation.js";

const state = {
  evaluations: [],
  evaluationsLoadStatus: 0,
  evaluation: {},
  evaluationLoadStatus: 0,
  evaluationCreateStatus: 0,
  evaluationLastCreatedId: 0,
  evaluationUpdateStatus: 0,
  evaluationDeleteStatus: 0,
  evalFileUploadStatus: 0,
  evalFileDeleteStatus: 0,
  evalFileNom: "",
  actions: {
    delete: "eva/deleteEvaluation",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_medtuteur",
      label: "IDMEDTUTEUR",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "eval_date",
      label: "Date",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "remarques",
      label: "Remarques",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "evalfile",
      label: "Fiche",
      typefiltre: "checkdoc",
      visible: true,
      liendoc: true,
      docfolder: "/rhfiles/evals/",
    },
  ],
};

const getters = {
  EvasInBD: (state) => {
    return state.evaluations.map((eva) => [
      eva.id,
      eva.id_medtuteur,
      eva.eval_date,
    ]);
  },
};

const mutations = {
  setEvaluations(state, evaluations) {
    // ajoute la col tri par défaut
    evaluations.map((obj, idx) => (obj.tri_defaut = idx));
    state.evaluations = evaluations;
  },
  setEvaluation(state, evaluation) {
    state.evaluation = evaluation;
  },
  resetEvaluation(state, idtut) {
    state.evaluation = {
      id: "",
      id_medtuteur: idtut,
      eval_date: "",
      remarques: "",
      evalfile: "",
    };
  },
  canceleditEvaluation(state, formdata) {
    state.evaluation.eval_date = formdata[0];
    state.evaluation.remarques = formdata[1];
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setEvaluationsLoadStatus(state, status) {
    state.evaluationsLoadStatus = status;
  },
  setEvaluationLoadStatus(state, status) {
    state.evaluationLoadStatus = status;
  },
  setEvaluationCreateStatus(state, status) {
    state.evaluationCreateStatus = status;
  },
  setEvaluationLastCreatedId(state, idEva) {
    state.evaluationLastCreatedId = idEva;
  },
  setEvaluationUpdateStatus(state, status) {
    state.evaluationUpdateStatus = status;
  },
  setEvaluationDeleteStatus(state, status) {
    state.evaluationDeleteStatus = status;
  },
  evaluationRemoveItem(state, idEva) {
    state.evaluations = state.evaluations.filter((eva) => eva.id != idEva);
  },
  setEvalFileNom(state, fileNom) {
    state.evalFileNom = fileNom;
    state.evaluation.evalfile = fileNom;
  },
  setEvalFileUploadStatus(state, status) {
    state.evalFileUploadStatus = status;
  },
  setEvalFileDeleteStatus(state, status) {
    state.evalFileDeleteStatus = status;
  },
};

const actions = {
  // charge l'ensemble des tutorats
  loadEvaluations({ commit }) {
    commit("setEvaluationsLoadStatus", 1);
    evaluationAPI
      .evaluationIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setEvaluations", response.data);
        commit("setEvaluationsLoadStatus", 2);
      })
      .catch(function () {
        commit("setEvaluations", []);
        commit("setEvaluationsLoadStatus", 3);
      });
  },
  // charge 1 tutorat
  loadEvaluation({ commit }, idEva) {
    commit("setEvaluationLoadStatus", 1);
    evaluationAPI
      .evaluationShow(idEva)
      .then(function (response) {
        commit("setEvaluation", response.data);
        commit("setEvalFileNom", response.data.evalfile);
        commit("setEvaluationLoadStatus", 2);
      })
      .catch(function () {
        commit("setEvaluation", {});
        commit("setEvaluationLoadStatus", 3);
      });
  },
  // Créer une nouvelle évaluation:
  createEvaluation({ commit, dispatch }, data) {
    commit("setEvaluationCreateStatus", 1);

    evaluationAPI
      .evaluationCreate(
        data.id_medtuteur,
        data.eval_date,
        data.remarques,
        data.evalfile
      )
      .then(function (response) {
        dispatch("loadEvaluations");
        commit("setEvaluationCreateStatus", 2);
        commit("setEvaluationLastCreatedId", response.data.id);
        commit("setEvalFileNom", response.data.evalfile);
        // recharge aussi la liste des évalution pour maj colonne "Dernière évaluation"
        dispatch("tut/loadTuteurs", null, { root: true });
      })
      .catch(function () {
        commit("setEvaluationCreateStatus", 3);
      });
  },
  // Met à jour les données d'une évaluation
  updateEvaluation({ commit, dispatch }, data) {
    commit("setEvaluationUpdateStatus", 1);

    evaluationAPI
      .evaluationUpdate(
        data.id,
        data.id_medtuteur,
        data.eval_date,
        data.remarques,
        data.evalfile
      )
      .then(function (response) {
        dispatch("loadEvaluations");
        commit("setEvaluation", response.data);
        commit("setEvalFileNom", response.data.evalfile);
        commit("setEvaluationUpdateStatus", 2);
        // recharge aussi la liste des évalution pour maj colonne "Dernière évaluation"
        dispatch("tut/loadTuteurs", null, { root: true });
      })
      .catch(function () {
        commit("setEvaluationUpdateStatus", 3);
      });
  },
  // supprime 1 évaluation
  deleteEvaluation({ commit, dispatch }, idEva) {
    commit("setEvaluationDeleteStatus", 1);
    evaluationAPI
      .evaluationDelete(idEva)
      .then(function () {
        commit("setEvaluationDeleteStatus", 2);
        dispatch("loadEvaluations");
        // recharge aussi la liste des évalution pour maj colonne "Dernière évaluation"
        dispatch("tut/loadTuteurs", null, { root: true });
      })
      .catch(function () {
        commit("setEvaluationDeleteStatus", 3);
      });
  },
  // upload la fiche d'éval et ajoute le nom du fichier au champ evalfile de la table evaluation
  uploadEvalFile({ commit, dispatch }, data) {
    commit("setEvalFileUploadStatus", 1);
    evaluationAPI
      .evalUploadFile(data.file, data.id)
      .then(function (response) {
        commit("setEvalFileNom", response.data);
        commit("setEvalFileUploadStatus", 2);
        dispatch("loadEvaluations");
      })
      .catch(function () {
        commit("setEvalFileUploadStatus", 3);
      });
  },
  // supprime la fiche d'éval
  deleteEvalFile({ commit, dispatch }, idEval) {
    commit("setEvalFileDeleteStatus", 1);
    evaluationAPI
      .evalDeleteFile(idEval)
      .then(function () {
        commit("setEvalFileNom", "");
        commit("setEvalFileDeleteStatus", 2);
        dispatch("loadEvaluations");
      })
      .catch(function () {
        commit("setEvalFileDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
