import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  hcactivitemedecinIndex: function () {
    return API.apiClient.get("/hcactivitemedecins");
  },
  // retourne un item (ID)
  hcactivitemedecinShow: function (id) {
    return API.apiClient.get("/hcactivitemedecins/" + id);
  },
  // pour créer un nouvel item
  hcactivitemedecinCreate: function (
    id_med,
    id_hcactivite,
    debut_date,
    fin_date
  ) {
    return API.apiClient.post("/hcactivitemedecins", {
      id_med: id_med,
      id_hcactivite: id_hcactivite,
      debut_date: debut_date,
      fin_date: fin_date,
    });
  },
  // met à jour un item
  hcactivitemedecinUpdate: function (
    id,
    id_med,
    id_hcactivite,
    debut_date,
    fin_date
  ) {
    return API.apiClient.put("/hcactivitemedecins/" + id, {
      id_med: id_med,
      id_hcactivite: id_hcactivite,
      debut_date: debut_date,
      fin_date: fin_date,
    });
  },
  // supprime un item
  hcactivitemedecinDelete: function (hcmId) {
    return API.apiClient.delete("/hcactivitemedecins/" + hcmId);
  },
};
