// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import formationstatutAPI from "@/services/rh/formationstatut.js";

const state = {
  statuts: [],
  statutsLoadStatus: 0,
};

const mutations = {
  setStatuts(state, statuts) {
    state.statuts = statuts;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setStatutsLoadStatus(state, status) {
    state.statutsLoadStatus = status;
  },
};

const actions = {
  loadStatuts({ commit }) {
    commit("setStatutsLoadStatus", 1);
    formationstatutAPI
      .statutIndex()
      .then(function (response) {
        commit("setStatuts", response.data);
        commit("setStatutsLoadStatus", 2);
      })
      .catch(function () {
        commit("setStatuts", []);
        commit("setStatutsLoadStatus", 3);
      });
  },
};

const getters = {
  statutSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....]
    return state.statuts.map((obj) => {
      return { value: obj.id, label: obj.statut };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
