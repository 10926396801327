// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import theseAPI from "@/services/rh/these.js";
import { toLocaleDateStr } from "@/fonctions";

const state = {
  theses: [],
  thesesLoadStatus: 0,
  these: {},
  theseLoadStatus: 0,
  theseCreateStatus: 0,
  theseLastCreatedId: 0,
  theseUpdateStatus: 0,
  theseDeleteStatus: 0,
  actions: {
    delete: "th/deleteThese",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_theses", params: { idmed: "id_med" } },
    },
    {
      prop: "titre",
      label: "Titre",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "faculte",
      label: "Faculté",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "pays",
      label: "Pays",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "statut_annee",
      label: "Statut",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "updated_at",
      label: "Dernière maj",
      typefiltre: "jdate",
      visible: true,
    },
  ],
  // formulaire
  // options pour le champ annee (années ajoutées avec getters)
  optAnneeInconnu: [{ value: "Inconnue", label: "-- Inconnue --" }],
};

const getters = {
  // dans form create pour vérifier si titre déjà attribué au méd.
  // options pour le champ annee : inconnu + année actuelle jusqu'à - 45 an
  optAnnee: (state) => {
    let optArray = state.optAnneeInconnu;
    const curYear = new Date().getFullYear(); // année en cours
    // loop pour récupérer les années
    for (let i = curYear; i >= curYear - 45; i--) {
      const iStr = i.toString(); // année en string
      optArray.push({ value: iStr, label: iStr });
    }

    return optArray;
  },
  TheseInBD: (state) => {
    // dans form create pour vérifier si titre déjà attribué au méd.
    return state.theses.map((t) => [
      t.id,
      t.id_med,
      t.id_fac,
      t.annee,
      t.id_statut,
    ]);
  },
};

const mutations = {
  setTheses(state, theses) {
    state.theses = theses;
  },
  setThese(state, these) {
    state.these = these;
  },
  resetThese(state, idmed) {
    state.these = {
      id: "",
      id_med: idmed,
      id_fac: "",
      titre: "",
      annee: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setThesesLoadStatus(state, status) {
    state.thesesLoadStatus = status;
  },
  setTheseLoadStatus(state, status) {
    state.theseLoadStatus = status;
  },
  setTheseCreateStatus(state, status) {
    state.theseCreateStatus = status;
  },
  setTheseLastCreatedId(state, idth) {
    state.theseLastCreatedId = idth;
  },
  setTheseUpdateStatus(state, status) {
    state.theseUpdateStatus = status;
  },
  setTheseDeleteStatus(state, status) {
    state.theseDeleteStatus = status;
  },
  theseRemoveItem(state, idth) {
    state.theses = state.theses.filter((th) => th.id != idth);
  },
};

const actions = {
  // charge l'ensemble des theses
  loadTheses({ commit }) {
    commit("setThesesLoadStatus", 1);
    theseAPI
      .theseIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          o.updated_at = toLocaleDateStr(o.updated_at);
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setTheses", response.data);
        commit("setThesesLoadStatus", 2);
      })
      .catch(function () {
        commit("setTheses", []);
        commit("setThesesLoadStatus", 3);
      });
  },
  // charge 1 these
  loadThese({ commit }, idFpg) {
    commit("setTheseLoadStatus", 1);
    theseAPI
      .theseShow(idFpg)
      .then(function (response) {
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setThese", response.data);
        commit("setTheseLoadStatus", 2);
      })
      .catch(function () {
        commit("setThese", {});
        commit("setTheseLoadStatus", 3);
      });
  },
  // Créer un nouveau these :
  // met les données du these créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createThese({ commit, dispatch }, data) {
    commit("setTheseCreateStatus", 1);
    theseAPI
      .theseCreate(
        data.id_med,
        data.id_fac,
        data.id_statut,
        data.titre,
        data.annee
      )
      .then(function (response) {
        dispatch("loadTheses");
        commit("setTheseCreateStatus", 2);
        commit("setTheseLastCreatedId", response.data.id);
        // Recharge aussi la liste des facultés pour maj le nbre d'occurence
        dispatch("fac/loadFacultes", null, { root: true });
      })
      .catch(function () {
        commit("setTheseCreateStatus", 3);
      });
  },
  // Met à jour les données d'un these
  updateThese({ commit, dispatch }, data) {
    commit("setTheseUpdateStatus", 1);
    theseAPI
      .theseUpdate(
        data.id,
        data.id_med,
        data.id_fac,
        data.id_statut,
        data.titre,
        data.annee
      )
      .then(function (response) {
        dispatch("loadTheses");
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setThese", response.data);
        commit("setTheseUpdateStatus", 2);
        // Recharge aussi la liste des facultés pour maj le nbre d'occurence
        dispatch("fac/loadFacultes", null, { root: true });
      })
      .catch(function () {
        commit("setTheseUpdateStatus", 3);
      });
  },
  // supprime 1 these
  deleteThese({ commit, dispatch }, idth) {
    commit("setTheseDeleteStatus", 1);
    theseAPI
      .theseDelete(idth)
      .then(function () {
        commit("setTheseDeleteStatus", 2);
        dispatch("loadTheses");
        // Recharge aussi la liste des facultés pour maj le nbre d'occurence
        dispatch("fac/loadFacultes", null, { root: true });
      })
      .catch(function () {
        commit("setTheseDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
