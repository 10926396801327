import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes dans un terminal -> php artisan route:list

export default {
  // liste items
  categorieIndex: function () {
    return API.apiClient.get("/fpostgradcategories");
  },
};
