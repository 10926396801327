// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import contrattypeAPI from "@/services/rh/contrattype.js";

const state = {
  contrattypes: [],
  contrattypesLoadStatus: 0,
};

const mutations = {
  setContrattypes(state, contrattypes) {
    state.contrattypes = contrattypes;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setContrattypesLoadStatus(state, contrattypes) {
    state.contrattypesLoadStatus = contrattypes;
  },
};

const actions = {
  loadContrattypes({ commit }) {
    commit("setContrattypesLoadStatus", 1);
    contrattypeAPI
      .contrattypesIndex()
      .then(function (response) {
        commit("setContrattypes", response.data);
        commit("setContrattypesLoadStatus", 2);
      })
      .catch(function () {
        commit("setContrattypes", []);
        commit("setContrattypesLoadStatus", 3);
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
