import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes dans un terminal -> php artisan route:list

export default {
  // liste items
  titreIndex: function () {
    return API.apiClient.get("/fcontinuetitres");
  },
  // retourne un item (ID)
  titreShow: function (id) {
    return API.apiClient.get("/fcontinuetitres/" + id);
  },
  // pour créer un nouvel item
  titreCreate: function (id_fcont_cat, titre) {
    return API.apiClient.post("/fcontinuetitres", {
      id_fcont_cat: id_fcont_cat,
      titre: titre,
    });
  },
  // met à jour un item
  titreUpdate: function (id, id_fcont_cat, titre) {
    return API.apiClient.put("/fcontinuetitres/" + id, {
      id_fcont_cat: id_fcont_cat,
      titre: titre,
    });
  },
  // supprime un item
  titreDelete: function (fcId) {
    return API.apiClient.delete("/fcontinuetitres/" + fcId);
  },
};
