import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  fpostgradIndex: function () {
    return API.apiClient.get("/fpostgrads");
  },
  // retourne un item (ID)
  fpostgradShow: function (id) {
    return API.apiClient.get("/fpostgrads/" + id);
  },
  // pour créer un nouvel item
  fpostgradCreate: function (
    id_med,
    id_fpost_titre,
    id_statut,
    precisions,
    annee,
    use_checklist
  ) {
    return API.apiClient.post("/fpostgrads", {
      id_med: id_med,
      id_fpost_titre: id_fpost_titre,
      id_statut: id_statut,
      precisions: precisions,
      annee: annee,
      use_checklist: use_checklist,
    });
  },
  // met à jour un item
  fpostgradUpdate: function (
    id,
    id_med,
    id_fpost_titre,
    id_statut,
    precisions,
    annee,
    use_checklist,
    fmhmig,
    afchosp,
    afcxhosp
  ) {
    return API.apiClient.put("/fpostgrads/" + id, {
      id_med: id_med,
      id_fpost_titre: id_fpost_titre,
      id_statut: id_statut,
      precisions: precisions,
      annee: annee,
      use_checklist: use_checklist,
      fmhmig: fmhmig,
      afchosp: afchosp,
      afcxhosp: afcxhosp,
    });
  },
  // supprime un item
  fpostgradDelete: function (fpgId) {
    return API.apiClient.delete("/fpostgrads/" + fpgId);
  },
};
