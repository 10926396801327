// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import fpostgradtitreAPI from "@/services/rh/fpostgradtitre.js";

const state = {
  titres: [],
  titresLoadStatus: 0,
};

const mutations = {
  setTitres(state, titres) {
    state.titres = titres;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setTitresLoadStatus(state, status) {
    state.titresLoadStatus = status;
  },
};

const actions = {
  loadTitres({ commit }) {
    commit("setTitresLoadStatus", 1);
    fpostgradtitreAPI
      .titreIndex()
      .then(function (response) {
        commit("setTitres", response.data);
        commit("setTitresLoadStatus", 2);
      })
      .catch(function () {
        commit("setTitres", []);
        commit("setTitresLoadStatus", 3);
      });
  },
};

const getters = {
  titreSelect: (state) => {
    // array des id des éléments dans l'ordre qu'ils doivent être triés
    const idOrdre = [71, 53, 58, 49, 52, 70, 98, 2, 1, 96, 95];

    return state.titres
      .filter((obj) => obj.fpostgrad_opt == 1)
      .map((obj) => {
        return { value: obj.id, label: obj.titre_pays, type: obj.id_fpost_cat };
      }) // format [ {value:'value', label:'label'}, ....]
      .sort((a, b) => idOrdre.indexOf(a.value) - idOrdre.indexOf(b.value));
  },
  fmhmigDisciplineSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....] .filter( obj => obj.fpostgrad_opt == 1)
    return state.titres
      .filter((obj) => obj.fmh_mig_opt == 1)
      .map((obj) => {
        return { value: obj.id, label: obj.titre_pays, type: obj.id_fpost_cat };
      });
  },
  afchospFmhBaseTitreSelect: (state) => {
    // retourne les titres qui servent d'option pour le FMH de base de la checklist AFC HOSP
    // array des id des éléments dans l'ordre qu'ils doivent être triés
    const idOrdre = [71, 53, 58, 49, 52, 70];
    return state.titres
      .filter((obj) => obj.afc_hosp_opt == 1)
      .map((obj) => {
        return { value: obj.id, label: obj.titre_pays };
      })
      .sort((a, b) => idOrdre.indexOf(a.value) - idOrdre.indexOf(b.value));
  },
  afcxhospAutreDiscSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....] .filter( obj => obj.fpostgrad_opt == 1)
    return state.titres
      .filter((obj) => obj.afc_xhosp_opt == 1)
      .map((obj) => {
        return { value: obj.id, label: obj.titre_pays, type: obj.id_fpost_cat };
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
