import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  evaluationIndex: function () {
    return API.apiClient.get("/evaluations");
  },
  // retourne un item (ID)
  evaluationShow: function (evaId) {
    return API.apiClient.get("/evaluations/" + evaId);
  },
  // pour créer un nouvel item
  evaluationCreate: function (id_medtuteur, eval_date, remarques, evalfile) {
    return API.apiClient.post("/evaluations", {
      id_medtuteur: id_medtuteur,
      eval_date: eval_date,
      remarques: remarques,
      evalfile: evalfile,
    });
  },
  // met à jour un item
  evaluationUpdate: function (
    id,
    id_medtuteur,
    eval_date,
    remarques,
    evalfile
  ) {
    return API.apiClient.put("/evaluations/" + id, {
      id_medtuteur: id_medtuteur,
      eval_date: eval_date,
      remarques: remarques,
      evalfile: evalfile,
    });
  },
  // supprime un item
  evaluationDelete: function (evaId) {
    return API.apiClient.delete("/evaluations/" + evaId);
  },
  // charge la fiche d'éval
  evalUploadFile: function (file, evalid) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id_eval", evalid);

    return API.apiClient.post("/evaluation-uploadevalfile", formData);
  },
  // supprime la fiche d'éval
  evalDeleteFile: function (evalid) {
    const formData = new FormData();
    formData.append("id_eval", evalid);
    return API.apiClient.post("/evaluation-supprimeevalfile", formData);
  },
};
