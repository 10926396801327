// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import tuteurAPI from "@/services/rh/tuteur.js";

const state = {
  tuteurs: [],
  tuteursLoadStatus: 0,
  tuteur: {},
  tuteurLoadStatus: 0,
  tuteurCreateStatus: 0,
  tuteurLastCreatedId: 0,
  tuteurUpdateStatus: 0,
  tuteurDeleteStatus: 0,
  actions: {
    delete: "tut/deleteTuteur",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_tuteur",
      label: "IDTUTEUR",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "id_pupille",
      label: "IDPUPILLE",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "tuteur",
      label: "Tuteur",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_pupilles", params: { idmed: "id_tuteur" } },
    },
    {
      prop: "pupille",
      label: "Pupille",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_tuteurs", params: { idmed: "id_pupille" } },
    },
    {
      prop: "debut_date",
      label: "Debut",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "fin_date",
      label: "Fin",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "lasteval_date",
      label: "Dernière évaluation",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "prochain_eval_date",
      label: "Trimestre prochaine évaluation",
      typefiltre: "multichoix",
      visible: true,
    },
  ],
};

const getters = {
  TutsInBD: (state) => {
    return state.tuteurs.map((tut) => [
      tut.id,
      tut.id_pupille,
      tut.id_tuteur,
      tut.debut_date,
    ]);
  },
};

const mutations = {
  setTuteurs(state, tuteurs) {
    state.tuteurs = tuteurs;
  },
  setTuteur(state, tuteur) {
    state.tuteur = tuteur;
  },
  resetTuteur(state, medParam) {
    state.tuteur = {
      id: "",
      id_pupille: medParam.pup,
      id_tuteur: medParam.tut,
      debut_date: "",
      fin_date: "",
      prochain_eval_date: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setTuteursLoadStatus(state, status) {
    state.tuteursLoadStatus = status;
  },
  setTuteurLoadStatus(state, status) {
    state.tuteurLoadStatus = status;
  },
  setTuteurCreateStatus(state, status) {
    state.tuteurCreateStatus = status;
  },
  setTuteurLastCreatedId(state, idTut) {
    state.tuteurLastCreatedId = idTut;
  },
  setTuteurUpdateStatus(state, status) {
    state.tuteurUpdateStatus = status;
  },
  setTuteurDeleteStatus(state, status) {
    state.tuteurDeleteStatus = status;
  },
  tuteurRemoveItem(state, idTut) {
    state.tuteurs = state.tuteurs.filter((tut) => tut.id != idTut);
  },
};

const actions = {
  // charge l'ensemble des tutorats
  loadTuteurs({ commit }) {
    commit("setTuteursLoadStatus", 1);
    tuteurAPI
      .tuteurIndex()
      .then(function (response) {
        // ajoute la col tri par défaut
        response.data.map((o, idx) => (o.tri_defaut = idx));
        commit("setTuteurs", response.data);
        commit("setTuteursLoadStatus", 2);
      })
      .catch(function () {
        commit("setTuteurs", []);
        commit("setTuteursLoadStatus", 3);
      });
  },
  // charge 1 tutorat
  loadTuteur({ commit }, idTut) {
    commit("setTuteurLoadStatus", 1);
    tuteurAPI
      .tuteurShow(idTut)
      .then(function (response) {
        commit("setTuteur", response.data);
        commit("setTuteurLoadStatus", 2);
      })
      .catch(function () {
        commit("setTuteur", {});
        commit("setTuteurLoadStatus", 3);
      });
  },
  // Créer un nouveau tutorat :
  // met les données du tutorat créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createTuteur({ commit, dispatch }, data) {
    commit("setTuteurCreateStatus", 1);
    tuteurAPI
      .tuteurCreate(
        data.id_pupille,
        data.id_tuteur,
        data.debut_date,
        data.fin_date,
        data.prochain_eval_date
      )
      .then(function (response) {
        dispatch("loadTuteurs");
        commit("setTuteurCreateStatus", 2);
        commit("setTuteurLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setTuteurCreateStatus", 3);
      });
  },
  // Met à jour les données d'un tutorat
  updateTuteur({ commit, dispatch }, data) {
    commit("setTuteurUpdateStatus", 1);
    tuteurAPI
      .tuteurUpdate(
        data.id,
        data.id_pupille,
        data.id_tuteur,
        data.debut_date,
        data.fin_date,
        data.prochain_eval_date
      )
      .then(function (response) {
        dispatch("loadTuteurs");
        commit("setTuteur", response.data);
        commit("setTuteurUpdateStatus", 2);
      })
      .catch(function () {
        commit("setTuteurUpdateStatus", 3);
      });
  },
  // supprime 1 tutorat
  deleteTuteur({ commit, dispatch }, idTut) {
    commit("setTuteurDeleteStatus", 1);
    tuteurAPI
      .tuteurDelete(idTut)
      .then(function () {
        commit("setTuteurDeleteStatus", 2);
        dispatch("loadTuteurs");
      })
      .catch(function () {
        commit("setTuteurDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
