// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import hcactivitemedecinAPI from "@/services/rh/hcactivitemedecin";

const state = {
  hcactivitemedecins: [],
  hcactivitemedecinsLoadStatus: 0,
  hcactivitemedecin: {},
  hcactivitemedecinLoadStatus: 0,
  hcactivitemedecinCreateStatus: 0,
  hcactivitemedecinLastCreatedId: 0,
  hcactivitemedecinUpdateStatus: 0,
  hcactivitemedecinDeleteStatus: 0,
  actions: {
    delete: "hcm/deleteHcactivitemedecin",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_hcactivites", params: { idmed: "id_med" } },
    },
    {
      prop: "categorie",
      label: "Type",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "hcactivite",
      label: "Activité",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "debut_date",
      label: "Début",
      typefiltre: "mdate",
      visible: true,
    },
    {
      prop: "fin_date",
      label: "fin",
      typefiltre: "mdate",
      visible: true,
    },
  ],
};

const getters = {
  // dans form create pour vérifier si activité HC déjà attribué au méd.
  HcactiviteInBD: (state) => {
    return state.hcactivitemedecins.map((a) => [
      a.id,
      a.id_med,
      a.id_hcactivite,
      a.debut_date,
      a.fin_date,
    ]);
  },
};

const mutations = {
  setHcactivitemedecins(state, hcactivitemedecins) {
    state.hcactivitemedecins = hcactivitemedecins;
  },
  setHcactivitemedecin(state, hcactivitemedecin) {
    state.hcactivitemedecin = hcactivitemedecin;
  },
  resetHCactivitemedecin(state, idmed) {
    state.hcactivitemedecin = {
      id: "",
      id_med: idmed,
      id_hcactivite: "",
      debut_date: "",
      fin_date: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setHcactivitemedecinsLoadStatus(state, status) {
    state.hcactivitemedecinsLoadStatus = status;
  },
  setHcactivitemedecinLoadStatus(state, status) {
    state.hcactivitemedecinLoadStatus = status;
  },
  setHcactivitemedecinCreateStatus(state, status) {
    state.hcactivitemedecinCreateStatus = status;
  },
  setHcactivitemedecinLastCreatedId(state, idhcm) {
    state.hcactivitemedecinLastCreatedId = idhcm;
  },
  setHcactivitemedecinUpdateStatus(state, status) {
    state.hcactivitemedecinUpdateStatus = status;
  },
  setHcactivitemedecinDeleteStatus(state, status) {
    state.hcactivitemedecinDeleteStatus = status;
  },
  hcactivitemedecinRemoveItem(state, idhcm) {
    state.hcactivitemedecins = state.hcactivitemedecins.filter(
      (hcm) => hcm.id != idhcm
    );
  },
};

const actions = {
  // charge l'ensemble des hcactivitemedecins
  loadHcactivitemedecins({ commit }) {
    commit("setHcactivitemedecinsLoadStatus", 1);
    hcactivitemedecinAPI
      .hcactivitemedecinIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setHcactivitemedecins", response.data);
        commit("setHcactivitemedecinsLoadStatus", 2);
      })
      .catch(function () {
        commit("setHcactivitemedecins", []);
        commit("setHcactivitemedecinsLoadStatus", 3);
      });
  },
  // charge 1 hcactivitemedecin
  loadHcactivitemedecin({ commit }, idFpg) {
    commit("setHcactivitemedecinLoadStatus", 1);
    hcactivitemedecinAPI
      .hcactivitemedecinShow(idFpg)
      .then(function (response) {
        commit("setHcactivitemedecin", response.data);
        commit("setHcactivitemedecinLoadStatus", 2);
      })
      .catch(function () {
        commit("setHcactivitemedecin", {});
        commit("setHcactivitemedecinLoadStatus", 3);
      });
  },
  // Créer un nouveau hcactivitemedecin :
  // met les données du hcactivitemedecin créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createHcactivitemedecin({ commit, dispatch }, data) {
    commit("setHcactivitemedecinCreateStatus", 1);
    hcactivitemedecinAPI
      .hcactivitemedecinCreate(
        data.id_med,
        data.id_hcactivite,
        data.debut_date,
        data.fin_date
      )
      .then(function (response) {
        dispatch("loadHcactivitemedecins");
        commit("setHcactivitemedecinCreateStatus", 2);
        commit("setHcactivitemedecinLastCreatedId", response.data.id);
        // Recharge aussi la liste des titres d'activités hors-clinique pour maj le nbre d'occurence
        dispatch("hc/loadHcactivites", null, { root: true });
      })
      .catch(function () {
        commit("setHcactivitemedecinCreateStatus", 3);
      });
  },
  // Met à jour les données d'un hcactivitemedecin
  updateHcactivitemedecin({ commit, dispatch }, data) {
    commit("setHcactivitemedecinUpdateStatus", 1);

    hcactivitemedecinAPI
      .hcactivitemedecinUpdate(
        data.id,
        data.id_med,
        data.id_hcactivite,
        data.debut_date,
        data.fin_date
      )
      .then(function (response) {
        dispatch("loadHcactivitemedecins");
        commit("setHcactivitemedecin", response.data);
        commit("setHcactivitemedecinUpdateStatus", 2);
        // Recharge aussi la liste des titres d'activités hors-clinique pour maj le nbre d'occurence
        dispatch("hc/loadHcactivites", null, { root: true });
      })
      .catch(function () {
        commit("setHcactivitemedecinUpdateStatus", 3);
      });
  },
  // supprime 1 hcactivitemedecin
  deleteHcactivitemedecin({ commit, dispatch }, idhcm) {
    commit("setHcactivitemedecinDeleteStatus", 1);
    hcactivitemedecinAPI
      .hcactivitemedecinDelete(idhcm)
      .then(function () {
        commit("setHcactivitemedecinDeleteStatus", 2);
        dispatch("loadHcactivitemedecins");
        // Recharge aussi la liste des titres d'activités hors-clinique pour maj le nbre d'occurence
        dispatch("hc/loadHcactivites", null, { root: true });
      })
      .catch(function () {
        commit("setHcactivitemedecinDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
