// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import paysAPI from "@/services/rh/pays.js";

const state = {
  pays: [],
  paysLoadStatus: 0,
};

const mutations = {
  setPays(state, pays) {
    state.pays = pays;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setPaysLoadStatus(state, pays) {
    state.paysLoadStatus = pays;
  },
};

const actions = {
  loadPays({ commit }) {
    commit("setPaysLoadStatus", 1);
    paysAPI
      .paysIndex()
      .then(function (response) {
        commit("setPays", response.data);
        commit("setPaysLoadStatus", 2);
      })
      .catch(function () {
        commit("setPays", []);
        commit("setPaysLoadStatus", 3);
      });
  },
};

const getters = {
  paysSelectSansEu: (state) => {
    let pays = state.pays.filter((p) => p["pays_fr"] != "Europe");
    // format [ {value:'value', label:'label'}, ....]
    return pays.map(function (obj) {
      return { value: obj.id, label: obj.pays_fr };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
