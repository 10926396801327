import store from "@/store";
/****       Fonctions         ****/
/** ajoute les options de filtre des tables dans le component AppRowfiltre  **/
export function colFilterOpts(col) {
  // proriétés de la colonne
  const colkey = col.prop;
  const collabel = col.label;
  const typef = col.typefiltre;
  // que les valeurs visibles
  const items = store.state.comp.items.filter((i) => i.visible);
  if (items.length > 0) {
    // array de toutes les valeurs visibles pour la colonne
    let vals = items.map((i) => {
      let val = i[colkey];
      // si null ou undefined return null
      val == null ? null : val;
      // si col avec lien vers doc
      if (typef == "checkdoc" && val) {
        val = collabel;
      }
      return val;
    });
    // supprime les doublons
    vals = [...new Set(vals)];
    // tri ASC avec null en premier
    triParColonne(vals, null, "ascending");
    // si filtre type date retourne [null, mindate, maxdate]
    if (["jdate", "mdate"].includes(typef)) {
      const dnull = vals.filter((v) => !v);
      let dminmax = vals
        .filter((v) => v)
        .filter((el, idx, arr) => idx == 0 || idx == arr.length - 1)
        // enlève les heures pour les dates de maj
        .map((i) => i.substring(0, 10));
      // si 1 seule date retournée, la prend 2 fois
      dminmax = dminmax.length == 1 ? dminmax.concat(dminmax) : dminmax;
      // concatène les 2 array
      vals = dnull.concat(dminmax);
    }
    return vals;
  } else {
    return [];
  }
}

/** TRI **/
export function triParColonne(arr, colonne, sens) {
  // sens = 'ascending' ou 'descending'
  const col = colonne ? colonne.split(".") : null;
  arr.sort((a, b) => {
    let comp = 0;
    a = col ? objGetNested(a, col) : a;
    b = col ? objGetNested(b, col) : b;

    if (a && b) {
      if (typeof a == "number" && typeof b == "number") {
        comp = a - b;
      } else if (typeof a == "string" && typeof b == "string") {
        if (a.match(/^\d{1,3}%$/) && b.match(/^\d{1,3}%$/)) {
          // si pourcentage compare valeurs numériques pour le tri (pas 100 avant 20 !)
          comp = Number(a.replace("%", "")) - Number(b.replace("%", ""));
        } else {
          comp = a.localeCompare(b);
        }
      }
    } else if (typeof a == "boolean" && typeof b == "boolean") {
      // true avant false
      comp = Number(b) - Number(a);
    } else {
      comp = a ? 1 : -1; // si a null comp=-1, si b null comp=1
    }

    // sens du tri
    if (sens == "descending") {
      return comp * -1;
    } else {
      return comp;
    }
  });
}

/**  évite l'erreur : "Cannot read property 'pays' of undefined"  **/
// source : https://hackernoon.com/accessing-nested-objects-in-javascript-f02f1bd6387f
export function objGetNested(obj, arrayProp) {
  return arrayProp.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    obj
  );
}

/** modifie la valeur d'un objet dans un array **/
export function changeObjValInArray(arrayObj, objKey, val) {
  var idx = arrayObj.findIndex((obj) => Object.keys(obj)[0] == objKey);
  arrayObj[idx][objKey] = val;
}

/**  supprime un objet d'un array  **/
export function deleteObjInArray(arrayObj, objKey) {
  var idx = arrayObj.findIndex((obj) => Object.keys(obj)[0] == objKey);
  arrayObj.splice(idx, 1);
}

/**  trim les strings d'un objet  **/
export function trimStringInObj(obj) {
  Object.keys(obj).forEach((k) => {
    if (typeof obj[k] == "string") {
      obj[k] = obj[k].trim();
    }
  });
  return obj;
}

/**  récupère les paramètres pour les routes dans les colonnes des tables **/
export function toRouterParams(params, row) {
  var para = {};
  if (params) {
    Object.keys(params).forEach((k) => {
      para[k] = row[params[k]];
    });
  }
  return para;
}

/** ajoute ou enlève des jours à une date **/
export function dateAddDay(date, day) {
  if (date) {
    let d = new Date(date);
    d.setDate(d.getDate() + day);

    return d.toISOString().substring(0, 10);
  } else {
    return null;
  }
}

/** gère l'affiche des icônes dans les champs dropdown des checklist **/
export function checklistIcon(item, group) {
  const color = item || group ? "#13ce66" : "#ff4949";
  const icon = item ? "el-icon-success" : "el-icon-warning-outline";
  return (
    "<i class='" +
    icon +
    "' style='color:" +
    color +
    "; font-size:16px; margin-right:10px' ></i>"
  );
}

/** compare les valeurs de 2 Obj et retourne true si aucune différence (noCompare = array de keys à ne pas comparer) */
// utilisé dans function compareFpostgrad
export function compareObjFpg(avant, apres, noCompare) {
  let all_egal = true;
  const toCompare = Object.keys(apres).filter((k) => !noCompare.includes(k)); // filtre les keys à comparer
  toCompare.forEach((c) => {
    if (avant[c] != apres[c]) {
      all_egal = false;
    }
  });
  return all_egal;
}

/** formFpostgrad compare les données à l'ouverture du form (conservées dans le localStorage) avec les données actuelles, en enlevant les champs updated_at **/
// retourne true si elle sont identiques
export function compareFpostgrad(avantStr, apresObj) {
  const avantObj = JSON.parse(avantStr);
  const idTitre = apresObj["id_fpost_titre"];
  const useCL = apresObj["use_checklist"];
  // liste des colonne à comparer
  const toCompare = ["id_med", "id_fpost_titre", "precisions", "use_checklist"];
  // si n'utilise pas la checklist compare la colonne id_statut
  if (useCL == 0) {
    toCompare.push("id_statut");
  }
  // si statut = terminé, compare 'annee'
  if (apresObj["id_statut"] == 3) {
    toCompare.push("annee");
  }
  // liste des colonnes qu'il ne faut pas comparer
  const noCompare = Object.keys(apresObj).filter((k) => !toCompare.includes(k));
  // compare les données de la fpg (sans checklist)
  //ne garde que les champs à comparer
  let fpg_egal = compareObjFpg(avantObj, apresObj, noCompare);
  // si utilise nouvelles données utilise checklist : compare les checklists correspondants à la fpg
  let checklist_egal = true;
  if (useCL == 1) {
    // si utilse checklist
    // ne compare pas les formations continues (qui sont enregsitrées ou effacées dans la table fcontinue)
    // les id et les dates
    const cl_noCompare = store.state.fctit.titres
      .map((t) => t.fpg_checklist)
      .filter((t) => t)
      .concat(["id", "id_fpost", "created_at", "updated_at"]);

    if (idTitre == 71) {
      // si FMH MIG
      checklist_egal = compareObjFpg(
        avantObj["fmhmig"],
        apresObj["fmhmig"],
        cl_noCompare
      );
      // si AFC XHOSP
    } else if (idTitre == 1) {
      checklist_egal = compareObjFpg(
        avantObj["afcxhosp"],
        apresObj["afcxhosp"],
        cl_noCompare
      );
      // si AFC HOSP
    } else if (idTitre == 2) {
      checklist_egal = compareObjFpg(
        avantObj["afchosp"],
        apresObj["afchosp"],
        cl_noCompare
      );
    }
  }
  return fpg_egal && checklist_egal;
}

/**  maj les checklists des fpostgard depuis les fcontinues  **/
export function fcontinueMajChecklist(idmed, checklistnom) {
  // liste des titres de formation continue inclus dans la checklist:
  const fctitres = store.state.fctit.titres.filter((t) => t[checklistnom] == 1);
  // créer l'objet des fcontinues de la checklist
  let clItems = {};
  fctitres.forEach((f) => {
    clItems[f.fpg_checklist] = {
      id: null,
      id_med: idmed,
      id_fcont_titre: f.id,
      titre: f.titre,
      mois: null,
      annee: null,
      annee_mois: null,
      autre_prec: null,
    };
  });
  // liste des id des titres de formation continue inclus dans la checklist:
  const titreids = fctitres.map((t) => t.id);
  // liste de toutes les formations continues du médecin incluses dans la checklist
  const fcmed = store.state.fc.fcontinues
    .filter((f) => f.id_med == idmed && titreids.includes(f.id_fcont_titre))
    // ajoute le champ dte_filtre pour trouver la formation la plus ancienne par titre
    .map((f) => {
      f.dte_filtre =
        f.annee == "Inconnue"
          ? "9999-99"
          : f.mois
          ? f.annee_mois
          : f.annee + "-99";
      return f;
    })
    // Pour chaque titre ne garde que la formation la plus ancienne
    // https://www.delftstack.com/howto/javascript/javascript-group-array-of-objects/
    // retourne un objet
    .reduce((grp, cur) => {
      const { id_fcont_titre } = cur; // récupère la valeur de la prop id_fcont_titre de l'obj
      if (grp[id_fcont_titre]) {
        grp[id_fcont_titre] =
          grp[id_fcont_titre].dte_filtre <= cur.dte_filtre
            ? grp[id_fcont_titre]
            : cur;
      } else {
        grp[id_fcont_titre] = cur;
      }
      return grp;
    }, {});
  // MAJ clItems avec les données des formations du médecin
  Object.keys(clItems).forEach((k) => {
    const medData = fcmed[clItems[k].id_fcont_titre];
    if (medData) {
      clItems[k].id = medData.id;
      clItems[k].mois = medData.mois;
      clItems[k].annee = medData.annee;
      clItems[k].annee_mois = medData.annee_mois;
      clItems[k].autre_prec = medData.autre_prec;
    }
  });

  return clItems;
}

/** convertit les dates stockées dans la bd en timezone UTC en locale time zone et en string au format yyyy-mm-dd hh:mm:ss **/
export function toLocaleDateStr(dte) {
  return dte
    ? (typeof dte == "string" ? new Date(dte) : dte).toLocaleString("sv-SE")
    : dte;
}
/** Date => string au format yyyy-mm-dd **/
export function toLocaleDateNoHStr(dte) {
  return toLocaleDateStr(dte).substring(0, 10);
}
/** Date => Mois string au format yyyy-mm **/
export function toLocaleDateMois(dte) {
  return toLocaleDateStr(dte).substring(0, 7);
}

/** Trouve le plus long substring commun entre 2 string **/
// Finding the longest common consecutive substring between two strings :
// https://www.tutorialspoint.com/finding-the-longest-common-consecutive-substring-between-two-strings-in-javascript
export function comSubStr(str1, str2) {
  const s1 = [...str1];
  const s2 = [...str2];
  const arr = Array(s2.length + 1)
    .fill(null)
    .map(() => {
      return Array(s1.length + 1).fill(null);
    });
  for (let j = 0; j <= s1.length; j += 1) {
    arr[0][j] = 0;
  }
  for (let i = 0; i <= s2.length; i += 1) {
    arr[i][0] = 0;
  }
  let len = 0;
  let col = 0;
  let row = 0;
  for (let i = 1; i <= s2.length; i += 1) {
    for (let j = 1; j <= s1.length; j += 1) {
      if (s1[j - 1] === s2[i - 1]) {
        arr[i][j] = arr[i - 1][j - 1] + 1;
      } else {
        arr[i][j] = 0;
      }
      if (arr[i][j] > len) {
        len = arr[i][j];
        col = j;
        row = i;
      }
    }
  }
  if (len === 0) {
    return "";
  }
  let res = "";
  while (arr[row][col] > 0) {
    res = s1[col - 1] + res;
    row -= 1;
    col -= 1;
  }
  return res;
}

/**  Trouve le plus long substring commun à tous les strings d'un array  **/
// return un string vide '' si aucun substring commun à tous les strings
export function arrayComSubstr(itemsList) {
  let repArray = [];

  for (let i = 0; i < itemsList.length; i++) {
    for (let k = i + 1; k < itemsList.length; k++) {
      const comStr = comSubStr(itemsList[i], itemsList[k]);
      if (comStr && !repArray.includes(comStr)) {
        repArray.push(comStr);
      }
    }
  }

  let searchTxt = "";
  const listLgth = itemsList.length;

  if (repArray.length == 1) {
    searchTxt = repArray[0];
  } else if (repArray.length > 1) {
    repArray.forEach((el) => {
      const isInAll =
        itemsList.filter((i) => i.includes(el)).length == listLgth;
      if (!isInAll) {
        repArray = repArray.filter((e) => e != el);
      }
    });
    searchTxt = repArray[0];
  }
  return searchTxt;
}

/**  Convertit l'index d'une colonne en nom de colonne XLS ex: 0 -> A, 25 -> Z, 26 -> AA, ect **/
// https://cwestblog.com/2013/09/05/javascript-snippet-convert-number-to-column-name
export function idxToXLSCol(num) {
  num++;
  for (var ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}

/** tout en minuscule, sans accent, sans espace, sans ponctuation ni parenthèses, etc -> que des lettres en minuscule **/
export function strNormalize(str) {
  return str
    .toLowerCase()
    .replace(/ |_/g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\W/g, "");
}
