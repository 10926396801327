import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes dans un terminal -> php artisan route:list

export default {
  // liste items
  hcactiviteIndex: function () {
    return API.apiClient.get("/hcactivites");
  },
  // retourne un item (ID)
  hcactiviteShow: function (id) {
    return API.apiClient.get("/hcactivites/" + id);
  },
  // pour créer un nouvel item
  hcactiviteCreate: function (id_hc_cat, hcactivite_descri) {
    return API.apiClient.post("/hcactivites", {
      id_hc_cat: id_hc_cat,
      hcactivite_descri: hcactivite_descri,
    });
  },
  // met à jour un item
  hcactiviteUpdate: function (id, id_hc_cat, hcactivite_descri) {
    return API.apiClient.put("/hcactivites/" + id, {
      id_hc_cat: id_hc_cat,
      hcactivite_descri: hcactivite_descri,
    });
  },
  // supprime un item
  hcactiviteDelete: function (hcId) {
    return API.apiClient.delete("/hcactivites/" + hcId);
  },
};
