// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import serviceAPI from "@/services/rh/service.js";

const state = {
  services: [],
  servicesLoadStatus: 0,
};

const mutations = {
  setServices(state, services) {
    state.services = services;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setServicesLoadStatus(state, services) {
    state.servicesLoadStatus = services;
  },
};

const actions = {
  loadServices({ commit }) {
    commit("setServicesLoadStatus", 1);
    serviceAPI
      .servicesIndex()
      .then(function (response) {
        commit("setServices", response.data);
        commit("setServicesLoadStatus", 2);
      })
      .catch(function () {
        commit("setServices", []);
        commit("setServicesLoadStatus", 3);
      });
  },
};

const getters = {
  serviceSelect: (state) => {
    return state.services.map((obj) => {
      return { value: obj.id, label: obj.service };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
