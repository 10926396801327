import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  fcontinueIndex: function () {
    return API.apiClient.get("/fcontinues");
  },
  // retourne un item (ID)
  fcontinueShow: function (id) {
    return API.apiClient.get("/fcontinues/" + id);
  },
  // pour créer un nouvel item
  fcontinueCreate: function (id_med, id_fcont_titre, autre_prec, mois, annee) {
    return API.apiClient.post("/fcontinues", {
      id_med: id_med,
      id_fcont_titre: id_fcont_titre,
      autre_prec: autre_prec,
      mois: mois,
      annee: annee,
    });
  },
  // met à jour un item
  fcontinueUpdate: function (
    id,
    id_med,
    id_fcont_titre,
    autre_prec,
    mois,
    annee
  ) {
    return API.apiClient.put("/fcontinues/" + id, {
      id_med: id_med,
      id_fcont_titre: id_fcont_titre,
      autre_prec: autre_prec,
      mois: mois,
      annee: annee,
    });
  },
  // supprime un item
  fcontinueDelete: function (fcId) {
    return API.apiClient.delete("/fcontinues/" + fcId);
  },
};
