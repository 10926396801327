// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import faculteAPI from "@/services/rh/faculte.js";
import { strNormalize, toLocaleDateStr } from "@/fonctions";

const state = {
  facultes: [],
  facultesLoadStatus: 0,
  faculte: {},
  faculteLoadStatus: 0,
  faculteCreateStatus: 0,
  faculteLastCreatedId: 0,
  faculteUpdateStatus: 0,
  faculteDeleteStatus: 0,
  actions: {
    delete: "fac/deleteFaculte",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "fac",
      label: "Faculté",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "pays",
      label: "Pays",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "occurences_fpr",
      label: "Form. prégrad. Nbre",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "occurences_these",
      label: "Thèses Nbre",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "created_at",
      label: "Créé le",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "updated_at",
      label: "Dernière maj",
      typefiltre: "jdate",
      visible: true,
    },
  ],
};

const getters = {
  facultesInBD: (state) => {
    // dans form create pour vérifier si faculté existe déjà dans la BD
    // nom de la fac en minuscule et sans espace et sans accents et sans ponctuation
    return state.facultes.map((f) => [f.id, f.id_pays, strNormalize(f.fac)]);
  },
  facSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....] .filter( obj => obj.fcontinue_opt == 1)
    return state.facultes.map((obj) => {
      return { value: obj.id, label: obj.fac, pays: obj.id_pays };
    });
  },
};

const mutations = {
  setFacultes(state, facultes) {
    state.facultes = facultes;
  },
  setFaculte(state, faculte) {
    state.faculte = faculte;
  },
  resetFaculte(state, idpays) {
    state.faculte = { id: "", id_pays: idpays, fac: "" };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setFacultesLoadStatus(state, status) {
    state.facultesLoadStatus = status;
  },
  setFaculteLoadStatus(state, status) {
    state.faculteLoadStatus = status;
  },
  setFaculteCreateStatus(state, status) {
    state.faculteCreateStatus = status;
  },
  setFaculteLastCreatedId(state, idfac) {
    state.faculteLastCreatedId = idfac;
  },
  setFaculteUpdateStatus(state, status) {
    state.faculteUpdateStatus = status;
  },
  setFaculteDeleteStatus(state, status) {
    state.faculteDeleteStatus = status;
  },
  faculteRemoveItem(state, idfac) {
    state.facultes = state.facultes.filter((fac) => fac.id != idfac);
  },
};

const actions = {
  // charge l'ensemble des facultes
  loadFacultes({ commit }) {
    commit("setFacultesLoadStatus", 1);
    faculteAPI
      .faculteIndex()
      .then(function (response) {
        const colDates = ["created_at", "updated_at"];
        response.data.map((o, idx) => {
          colDates.forEach((d) => (o[d] = toLocaleDateStr(o[d])));
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
          // ajoute la colonne nodelete (pour empêcher d'effacer les élements utilisés dans des tables)
          o.nodelete = o.occurences_fpr > 0 || o.occurences_these > 0;
        });
        commit("setFacultes", response.data);
        commit("setFacultesLoadStatus", 2);
      })
      .catch(function () {
        commit("setFacultes", []);
        commit("setFacultesLoadStatus", 3);
      });
  },
  // charge 1 faculte
  loadFaculte({ commit }, idfac) {
    commit("setFaculteLoadStatus", 1);
    faculteAPI
      .faculteShow(idfac)
      .then(function (response) {
        const colDates = ["created_at", "updated_at"];
        colDates.forEach(
          (d) => (response.data[d] = toLocaleDateStr(response.data[d]))
        );
        commit("setFaculte", response.data);
        commit("setFaculteLoadStatus", 2);
      })
      .catch(function () {
        commit("setFaculte", {});
        commit("setFaculteLoadStatus", 3);
      });
  },
  // Créer un nouveau faculte :
  // met les données du faculte créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createFaculte({ commit, dispatch }, data) {
    commit("setFaculteCreateStatus", 1);
    faculteAPI
      .faculteCreate(data.id_pays, data.fac)
      .then(function (response) {
        dispatch("loadFacultes");
        commit("setFaculteCreateStatus", 2);
        commit("setFaculteLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setFaculteCreateStatus", 3);
      });
  },
  // Met à jour les données d'un faculte
  updateFaculte({ commit, dispatch }, data) {
    commit("setFaculteUpdateStatus", 1);
    faculteAPI
      .faculteUpdate(data.id, data.id_pays, data.fac)
      .then(function (response) {
        dispatch("loadFacultes");
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setFaculte", response.data);
        commit("setFaculteUpdateStatus", 2);
        // reload aussi les tables avec des facultés => maj
        dispatch("fpr/loadFpregrads", null, { root: true });
        dispatch("th/loadTheses", null, { root: true });
      })
      .catch(function () {
        commit("setFaculteUpdateStatus", 3);
      });
  },
  // supprime 1 faculte
  deleteFaculte({ commit, dispatch }, idfac) {
    commit("setFaculteDeleteStatus", 1);
    faculteAPI
      .faculteDelete(idfac)
      .then(function () {
        commit("setFaculteDeleteStatus", 2);
        dispatch("loadFacultes");
      })
      .catch(function () {
        commit("setFaculteDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
