// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import fpostgradsiteAPI from "@/services/rh/fpostgradsite.js";

const state = {
  sites: [],
  sitesLoadStatus: 0,
};

const mutations = {
  setSites(state, sites) {
    state.sites = sites;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setSitesLoadStatus(state, status) {
    state.sitesLoadStatus = status;
  },
};

const actions = {
  loadSites({ commit }) {
    commit("setSitesLoadStatus", 1);
    fpostgradsiteAPI
      .siteIndex()
      .then(function (response) {
        commit("setSites", response.data);
        commit("setSitesLoadStatus", 2);
      })
      .catch(function () {
        commit("setSites", []);
        commit("setSitesLoadStatus", 3);
      });
  },
};

const getters = {
  afchospSiteSelect: (state) => {
    return state.sites
      .filter((obj) => obj.afc_hosp_opt == 1)
      .map((obj) => {
        return { value: obj.id, label: obj.site };
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
