// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import fpostgradAPI from "@/services/rh/fpostgrad.js";
import { toLocaleDateStr } from "@/fonctions";

const state = {
  fpostgrads: [],
  fpostgradsLoadStatus: 0,
  fpostgrad: {},
  fmhbase: {},
  fpostgradLoadStatus: 0,
  fpostgradCreateStatus: 0,
  fpostgradLastCreatedId: 0,
  fpostgradUpdateStatus: 0,
  fpostgradDeleteStatus: 0,
  actions: {
    delete: "fpg/deleteFpostgrad",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_fpostgrads", params: { idmed: "id_med" } },
    },
    {
      prop: "categorie",
      label: "Type",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "titre",
      label: "Titre",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "precisions",
      label: "Titre précisions",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "statut_annee",
      label: "Statut",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "updated_at",
      label: "Dernière maj",
      typefiltre: "jdate",
      visible: true,
    },
  ],
  // formulaire
  // options pour le champ annee (années ajoutées avec getters)
  optAnneeInconnu: [{ value: "Inconnue", label: "-- Inconnue --" }],
};

const getters = {
  // dans form create pour vérifier si titre déjà attribué au méd.
  // options pour le champ annee : inconnu + année actuelle jusqu'à - 45 an
  optAnnee: (state) => {
    let optArray = state.optAnneeInconnu;
    const curYear = new Date().getFullYear(); // année en cours
    // loop pour récupérer les années
    for (let i = curYear; i >= curYear - 45; i--) {
      const iStr = i.toString(); // année en string
      optArray.push({ value: iStr, label: iStr });
    }

    return optArray;
  },
  FpostgradInBD: (state) => {
    return state.fpostgrads.map((f) => [f.id, f.id_med, f.id_fpost_titre]);
  },
  // options pour le FMH de base de la checklist de l'AFC HOSP
  afchospFmhSelect: (state, getters, rootState, rootGetters) => {
    // format [ {value:'value', label:'label'}, ....]
    const fhmTitreIds = rootGetters["fpgtit/afchospFmhBaseTitreSelect"].map(
      (obj) => obj.value
    );
    const idmed = state.fpostgrad.id_med;
    // que les titres du médecin concerné et terminés (id_statut == 3)
    return (
      state.fpostgrads
        .filter(
          (obj) =>
            fhmTitreIds.includes(obj.id_fpost_titre) &&
            obj.id_med == idmed &&
            obj.id_statut == 3
        )
        .map((obj) => {
          return {
            value: obj.id,
            label: obj.titre + " - " + (obj.annee ? obj.annee : "Inconnue"),
          };
        })
        // tri sur label asc
        .sort((a, b) => {
          return a.label == b.label ? 0 : a.label < b.label ? -1 : 1;
        })
    );
  },
};

const mutations = {
  setFpostgrads(state, fpostgrads) {
    state.fpostgrads = fpostgrads;
  },
  setFpostgrad(state, fpostgrad) {
    state.fpostgrad = fpostgrad;
  },
  resetFpostgrad(state, idmed) {
    state.fpostgrad = {
      id: "",
      id_med: idmed,
      id_fpost_titre: "",
      id_statut: "",
      precisions: "",
      annee: "",
      use_checklist: 1,
      updated_at: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setFpostgradsLoadStatus(state, status) {
    state.fpostgradsLoadStatus = status;
  },
  setFpostgradLoadStatus(state, status) {
    state.fpostgradLoadStatus = status;
  },
  setFpostgradCreateStatus(state, status) {
    state.fpostgradCreateStatus = status;
  },
  setFpostgradLastCreatedId(state, idfpg) {
    state.fpostgradLastCreatedId = idfpg;
  },
  setFpostgradUpdateStatus(state, status) {
    state.fpostgradUpdateStatus = status;
  },
  setFpostgradDeleteStatus(state, status) {
    state.fpostgradDeleteStatus = status;
  },
  fpostgradRemoveItem(state, idfpg) {
    state.fpostgrads = state.fpostgrads.filter((cont) => cont.id != idfpg);
  },
  // FMH de base pour la checklist AFC HOSP
  setFmhbase(state, fmhbase) {
    state.fmhbase = fmhbase;
  },
  resetFmhbase(state, idmed) {
    state.fmhbase = {
      id: "",
      id_med: idmed,
      id_fpost_titre: "",
      id_statut: 3,
      precisions: "",
      annee: "",
      use_checklist: 0,
      updated_at: "",
    };
  },
};

const actions = {
  // charge l'ensemble des fpostgrads
  loadFpostgrads({ commit }) {
    commit("setFpostgradsLoadStatus", 1);
    fpostgradAPI
      .fpostgradIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          o.updated_at = toLocaleDateStr(o.updated_at);
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setFpostgrads", response.data);
        commit("setFpostgradsLoadStatus", 2);
      })
      .catch(function () {
        commit("setFpostgrads", []);
        commit("setFpostgradsLoadStatus", 3);
      });
  },
  // charge 1 fpostgrad
  loadFpostgrad({ commit }, idFpg) {
    commit("setFpostgradLoadStatus", 1);
    fpostgradAPI
      .fpostgradShow(idFpg)
      .then(function (response) {
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setFpostgrad", response.data);
        commit("setFpostgradLoadStatus", 2);
      })
      .catch(function () {
        commit("setFpostgrad", {});
        commit("setFpostgradLoadStatus", 3);
      });
  },
  // Créer un nouveau fpostgrad :
  // met les données du fpostgrad créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createFpostgrad({ commit, dispatch }, data) {
    commit("setFpostgradCreateStatus", 1);
    fpostgradAPI
      .fpostgradCreate(
        data.id_med,
        data.id_fpost_titre,
        data.id_statut,
        data.precisions,
        data.annee,
        data.use_checklist
      )
      .then(function (response) {
        dispatch("loadFpostgrads");
        commit("setFpostgradCreateStatus", 2);
        commit("setFpostgradLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setFpostgradCreateStatus", 3);
      });
  },
  // Met à jour les données d'un fpostgrad
  updateFpostgrad({ commit, dispatch }, data) {
    commit("setFpostgradUpdateStatus", 1);
    fpostgradAPI
      .fpostgradUpdate(
        data.id,
        data.id_med,
        data.id_fpost_titre,
        data.id_statut,
        data.precisions,
        data.annee,
        data.use_checklist,
        JSON.stringify(data.fmhmig),
        JSON.stringify(data.afchosp),
        JSON.stringify(data.afcxhosp)
      )
      .then(function (response) {
        dispatch("loadFpostgrads");
        response.data.updated_at = toLocaleDateStr(response.data.updated_at);
        commit("setFpostgrad", response.data);
        commit("setFpostgradUpdateStatus", 2);
      })
      .catch(function () {
        commit("setFpostgradUpdateStatus", 3);
      });
  },
  // supprime 1 fpostgrad
  deleteFpostgrad({ commit, dispatch }, idfpg) {
    commit("setFpostgradDeleteStatus", 1);
    fpostgradAPI
      .fpostgradDelete(idfpg)
      .then(function () {
        commit("setFpostgradDeleteStatus", 2);
        dispatch("loadFpostgrads");
      })
      .catch(function () {
        commit("setFpostgradDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
