// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import fpostgradcategorieAPI from "@/services/rh/fpostgradcategorie.js";

const state = {
  categories: [],
  categoriesLoadStatus: 0,
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setCategoriesLoadStatus(state, status) {
    state.categoriesLoadStatus = status;
  },
};

const actions = {
  loadCategories({ commit }) {
    commit("setCategoriesLoadStatus", 1);
    fpostgradcategorieAPI
      .categorieIndex()
      .then(function (response) {
        commit("setCategories", response.data);
        commit("setCategoriesLoadStatus", 2);
      })
      .catch(function () {
        commit("setCategories", []);
        commit("setCategoriesLoadStatus", 3);
      });
  },
};

const getters = {
  categorieSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....] // ne prend pa sla catégorie FA (formation approfondie)
    return state.categories
      .filter((obj) => obj.id != 4)
      .map((obj) => {
        return { value: obj.id, label: obj.categorie };
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
