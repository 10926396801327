// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import fpregradAPI from "@/services/rh/fpregrad.js";

const state = {
  fpregrads: [],
  fpregradsLoadStatus: 0,
  fpregrad: {},
  fpregradLoadStatus: 0,
  fpregradCreateStatus: 0,
  fpregradLastCreatedId: 0,
  fpregradUpdateStatus: 0,
  fpregradDeleteStatus: 0,
  actions: {
    delete: "fpr/deleteFpregrad",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: { route: "med_fpregrads", params: { idmed: "id_med" } },
    },
    {
      prop: "titre",
      label: "Titre",
      typefiltre: "autocompl",
      visible: true,
    },
    {
      prop: "titre_prec",
      label: "Titre précisions",
      typefiltre: "autocompl",
      visible: true,
    },

    {
      prop: "faculte",
      label: "Faculté",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "pays",
      label: "Pays",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "annee",
      label: "Année",
      typefiltre: "multichoix",
      visible: true,
    },
  ],
  // formulaire
  // options pour le champ annee (années ajoutées avec getters)
  optAnneeInconnu: [{ value: "Inconnue", label: "-- Inconnue --" }],
};

const getters = {
  // dans form create pour vérifier si titre déjà attribué au méd.
  // options pour le champ annee : inconnu + année actuelle jusqu'à - 45 an
  optAnnee: (state) => {
    let optArray = state.optAnneeInconnu;
    const curYear = new Date().getFullYear(); // année en cours
    // loop pour récupérer les années
    for (let i = curYear; i >= curYear - 45; i--) {
      const iStr = i.toString(); // année en string
      optArray.push({ value: iStr, label: iStr });
    }

    return optArray;
  },
  FpregradInBD: (state) => {
    // dans form create pour vérifier si titre déjà attribué au méd.
    return state.fpregrads.map((f) => [f.id, f.id_med, f.id_fac, f.annee]);
  },
};

const mutations = {
  setFpregrads(state, fpregrads) {
    state.fpregrads = fpregrads;
  },
  setFpregrad(state, fpregrad) {
    state.fpregrad = fpregrad;
  },
  resetFpregrad(state, idmed) {
    state.fpregrad = {
      id: "",
      id_med: idmed,
      titre: "",
      titre_prec: "",
      annee: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setFpregradsLoadStatus(state, status) {
    state.fpregradsLoadStatus = status;
  },
  setFpregradLoadStatus(state, status) {
    state.fpregradLoadStatus = status;
  },
  setFpregradCreateStatus(state, status) {
    state.fpregradCreateStatus = status;
  },
  setFpregradLastCreatedId(state, idfpr) {
    state.fpregradLastCreatedId = idfpr;
  },
  setFpregradUpdateStatus(state, status) {
    state.fpregradUpdateStatus = status;
  },
  setFpregradDeleteStatus(state, status) {
    state.fpregradDeleteStatus = status;
  },
  fpregradRemoveItem(state, idfpr) {
    state.fpregrads = state.fpregrads.filter((fpr) => fpr.id != idfpr);
  },
};

const actions = {
  // charge l'ensemble des fpregrads
  loadFpregrads({ commit }) {
    commit("setFpregradsLoadStatus", 1);
    fpregradAPI
      .fpregradIndex()
      .then(function (response) {
        response.data.map((o, idx) => {
          // ajoute la col tri par défaut
          o.tri_defaut = idx;
        });
        commit("setFpregrads", response.data);
        commit("setFpregradsLoadStatus", 2);
      })
      .catch(function () {
        commit("setFpregrads", []);
        commit("setFpregradsLoadStatus", 3);
      });
  },
  // charge 1 fpregrad
  loadFpregrad({ commit }, idFpg) {
    commit("setFpregradLoadStatus", 1);
    fpregradAPI
      .fpregradShow(idFpg)
      .then(function (response) {
        commit("setFpregrad", response.data);
        commit("setFpregradLoadStatus", 2);
      })
      .catch(function () {
        commit("setFpregrad", {});
        commit("setFpregradLoadStatus", 3);
      });
  },
  // Créer un nouveau fpregrad :
  // met les données du fpregrad créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createFpregrad({ commit, dispatch }, data) {
    commit("setFpregradCreateStatus", 1);
    fpregradAPI
      .fpregradCreate(
        data.id_med,
        data.id_fac,
        data.titre,
        data.titre_prec,
        data.annee
      )
      .then(function (response) {
        dispatch("loadFpregrads");
        commit("setFpregradCreateStatus", 2);
        commit("setFpregradLastCreatedId", response.data.id);
        // Recharge aussi la liste des facultés pour maj le nbre d'occurence
        dispatch("fac/loadFacultes", null, { root: true });
      })
      .catch(function () {
        commit("setFpregradCreateStatus", 3);
      });
  },
  // Met à jour les données d'un fpregrad
  updateFpregrad({ commit, dispatch }, data) {
    commit("setFpregradUpdateStatus", 1);
    fpregradAPI
      .fpregradUpdate(
        data.id,
        data.id_med,
        data.id_fac,
        data.titre,
        data.titre_prec,
        data.annee
      )
      .then(function (response) {
        dispatch("loadFpregrads");
        commit("setFpregrad", response.data);
        commit("setFpregradUpdateStatus", 2);
        // Recharge aussi la liste des facultés pour maj le nbre d'occurence
        dispatch("fac/loadFacultes", null, { root: true });
      })
      .catch(function () {
        commit("setFpregradUpdateStatus", 3);
      });
  },
  // supprime 1 fpregrad
  deleteFpregrad({ commit, dispatch }, idfpr) {
    commit("setFpregradDeleteStatus", 1);
    fpregradAPI
      .fpregradDelete(idfpr)
      .then(function () {
        commit("setFpregradDeleteStatus", 2);
        dispatch("loadFpregrads");
        // Recharge aussi la liste des facultés pour maj le nbre d'occurence
        dispatch("fac/loadFacultes", null, { root: true });
      })
      .catch(function () {
        commit("setFpregradDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
