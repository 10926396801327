// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import finmotifAPI from "@/services/rh/finmotif.js";

const state = {
  finmotifs: [],
  finmotifsLoadStatus: 0,
};

const mutations = {
  setFinmotifs(state, finmotifs) {
    state.finmotifs = finmotifs;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setFinmotifsLoadStatus(state, finmotifs) {
    state.finmotifsLoadStatus = finmotifs;
  },
};

const actions = {
  loadFinmotifs({ commit }) {
    commit("setFinmotifsLoadStatus", 1);
    finmotifAPI
      .finmotifsIndex()
      .then(function (response) {
        commit("setFinmotifs", response.data);
        commit("setFinmotifsLoadStatus", 2);
      })
      .catch(function () {
        commit("setFinmotifs", []);
        commit("setFinmotifsLoadStatus", 3);
      });
  },
};

const getters = {
  finMotifSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....]
    return state.finmotifs.map((obj) => {
      return { value: obj.id, label: obj.fin_motif };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
