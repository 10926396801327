import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import guest from "@/middleware/guest";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { middleware: [guest] },
    redirect: "/login",
  },
  // Login
  {
    path: "/log",
    name: "log",
    meta: { middleware: [guest] },
    component: () => import("@/views/Log"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "login",
        meta: { middleware: [guest] },
        component: () => import("@/views/Login"),
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        meta: { middleware: [guest] },
        component: () => import("@/views/ForgotPassword"),
      },
      {
        path: "/reset-password",
        name: "resetPassword",
        meta: { middleware: [guest] },
        component: () => import("@/views/ResetPassword"),
      },
    ],
  },
  // Dans l'application : après login
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { middleware: [auth] },
    component: () => import("@/views/Dashboard"),
    redirect: "/medecins",
    children: [
      // gestion des users =================================================
      {
        path: "/user-updatepw",
        name: "user_updatepw",
        meta: { middleware: [auth] },
        component: () => import("@/views/UserUpdatepw"),
      },
      {
        path: "/users",
        name: "users",
        meta: { middleware: [auth, admin] },
        component: () => import("@/views/Users"),
      },
      {
        path: "/users/:iduser",
        name: "useredit",
        meta: { middleware: [auth, admin] },
        component: () => import("@/views/UsersFormedit"),
      },
      {
        path: "/users/create",
        name: "usercreate",
        meta: { middleware: [auth, admin] },
        component: () => import("@/views/UsersFormcreate"),
      },
      //================= gestion des options des tables ==================================
      // Facultés (option tables Formations prégraduées et Thèses) ======================
      {
        path: "/facultes",
        name: "facultes",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/listes/ListFaculte"),
      },
      {
        path: "/facultes/create",
        name: "facultecreate",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/pages/FaculteCreate"),
      },
      {
        path: "/facultes/:idfac",
        name: "faculteedit",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/pages/FaculteEdit"),
      },
      // Activités hors clinique TITRES ( options table Activités hors-clinique ) =====================================================
      {
        path: "/hcactivitetitres",
        name: "hcactivitetitres",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/listes/ListHcactivitetitre"),
      },
      {
        path: "/hcactivitetitres/create",
        name: "hcactivitetitrecreate",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/pages/HcactivitetitreCreate"),
      },
      {
        path: "/hcactivitetitres/:idhctitre",
        name: "hcactivitetitreedit",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/pages/HcactivitetitreEdit"),
      },
      // Formations continue TITRES ( option de la table Formations continues)  ===========================================================
      {
        path: "/fcontinuetitres",
        name: "fcontinuetitres",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/listes/ListFcontinuetitre"),
      },
      {
        path: "/fcontinuetitres/create",
        name: "fcontinuetitrecreate",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/pages/FconttitreCreate"),
      },
      {
        path: "/fcontinues/:idfctitre",
        name: "fcontinuetitreedit",
        meta: { middleware: [auth] },
        component: () => import("@/components/rh/pages/FconttitreEdit"),
      },
      // =========================  APP URG-RH ===========================================================
      {
        path: "/rh",
        name: "rh",
        meta: { middleware: [auth] },
        component: () => import("@/views/AppRh"),
        children: [
          {
            path: "/medecins",
            name: "medecins",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListMedecin"),
          },
          {
            path: "/medecins/create",
            name: "medecincreate",
            meta: { middleware: [auth] },
            component: () =>
              import("@/components/rh/formulaires/FormMedecincreate"),
          },
          // Détail médecin ===========================================
          {
            path: "/medecins/:idmed",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/MedecinDetail"),
            children: [
              {
                path: "",
                name: "donnees_perso",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/formulaires/FormMedecinedit"),
              },
              // contrats
              {
                path: "contrats",
                name: "med_contrats",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedcontrat"),
              },
              {
                path: "contrats/create",
                name: "med_contrats_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailContratcreate"),
              },
              {
                path: "contrats/:idcont",
                name: "med_contrats_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailContratedit"),
              },
              // formations post-graduées
              {
                path: "fpostgrads",
                name: "med_fpostgrads",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedfpostgrad"),
              },
              {
                path: "fpostgrads/create",
                name: "med_fpostgrads_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailFpostgradcreate"),
              },
              {
                path: "fpostgrads/:idfpg",
                name: "med_fpostgrads_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailFpostgradedit"),
              },
              // formations continues
              {
                path: "fcontinues",
                name: "med_fcontinues",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedfcontinue"),
              },
              {
                path: "fcontinues/create",
                name: "med_fcontinues_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailFcontinuecreate"),
              },
              {
                path: "fcontinues/:idfc",
                name: "med_fcontinues_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailFcontinueedit"),
              },
              // activités hors clinique
              {
                path: "hcactivites",
                name: "med_hcactivites",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedhcactivite"),
              },
              {
                path: "hcactivites/create",
                name: "med_hcactivites_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailHcactivitecreate"),
              },
              {
                path: "hcactivites/:idhcm",
                name: "med_hcactivites_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailHcactiviteedit"),
              },
              // Tuteurs
              {
                path: "tuteurs",
                name: "med_tuteurs",
                meta: { middleware: [auth] },
                component: () => import("@/components/rh/listes/ListMedtuteur"),
              },
              {
                path: "tuteurs/create",
                name: "med_tuteurs_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailTuteurcreate"),
              },
              {
                path: "tuteurs/:idtutorat",
                name: "med_tuteurs_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailTuteuredit"),
              },
              // Evaluations
              {
                path: "tuteurs/:idtutorat/evaluations/create",
                name: "med_tut_evaluations_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailEvaluationcreate"),
              },
              {
                path: "tuteurs/:idtutorat/evaluations/:ideval",
                name: "med_tut_evaluations_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailEvaluationedit"),
              },
              // Pupilles
              {
                path: "pupilles",
                name: "med_pupilles",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedpupille"),
              },
              {
                path: "pupilles/create",
                name: "med_pupilles_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailPupillecreate"),
              },
              {
                path: "pupilles/:idtutorat",
                name: "med_pupilles_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailPupilleedit"),
              },
              // Evaluations
              {
                path: "pupilles/:idtutorat/evaluations/create",
                name: "med_pup_evaluations_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailEvaluationcreate"),
              },
              {
                path: "pupilles/:idtutorat/evaluations/:ideval",
                name: "med_pup_evaluations_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailEvaluationedit"),
              },
              // formations prégraduées
              {
                path: "fpregrads",
                name: "med_fpregrads",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedfpregrad"),
              },
              {
                path: "fpregrads/create",
                name: "med_fpregrads_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailFpregradcreate"),
              },
              {
                path: "fpregrads/:idfpr",
                name: "med_fpregrads_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailFpregradedit"),
              },
              // thèses
              {
                path: "theses",
                name: "med_theses",
                meta: { middleware: [auth] },
                component: () => import("@/components/rh/listes/ListMedthese"),
              },
              {
                path: "theses/create",
                name: "med_theses_create",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailThesecreate"),
              },
              {
                path: "theses/:idth",
                name: "med_theses_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/pages/MedecinDetailTheseedit"),
              },
              // Parcours cliniques
              {
                path: "parcourscliniques",
                name: "med_parcourscliniques",
                meta: { middleware: [auth] },
                component: () =>
                  import("@/components/rh/listes/ListMedparcoursclinique"),
              },
              {
                path: "parcourscliniques/create",
                name: "med_parcourscliniques_create",
                meta: { middleware: [auth] },
                component: () =>
                  import(
                    "@/components/rh/pages/MedecinDetailParcourscliniquecreate"
                  ),
              },
              {
                path: "parcourscliniques/:idpc",
                name: "med_parcourscliniques_edit",
                meta: { middleware: [auth] },
                component: () =>
                  import(
                    "@/components/rh/pages/MedecinDetailParcourscliniqueedit"
                  ),
              },
            ],
            //============================  Fin détail médecin =================================
          },
          // Contrats ========================================================================
          {
            path: "/contrats",
            name: "contrats",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListContrat"),
          },
          {
            path: "/contrats/create",
            name: "contratcreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/ContratCreate"),
          },
          {
            path: "/contrats/:idcont",
            name: "contratedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/ContratEdit"),
          },
          // formations poste-graduées ====================================================
          {
            path: "/fpostgrads",
            name: "fpostgrads",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListFpostgrad"),
          },
          {
            path: "/fpostgrads/create",
            name: "fpostgradcreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/FpostgradCreate"),
          },
          {
            path: "/fpostgrads/:idfpg",
            name: "fpostgradedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/FpostgradEdit"),
          },
          // formations continue ===========================================================
          {
            path: "/fcontinues",
            name: "fcontinues",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListFcontinue"),
          },
          {
            path: "/fcontinues/create",
            name: "fcontinuecreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/FcontinueCreate"),
          },
          {
            path: "/fcontinues/:idfc",
            name: "fcontinueedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/FcontinueEdit"),
          },
          // activités hors clinique =====================================================
          {
            path: "/hcactivites",
            name: "hcactivites",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListHcactivite"),
          },
          {
            path: "/hcactivites/create",
            name: "hcactivitecreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/HcactiviteCreate"),
          },
          {
            path: "/hcactivites/:idhcm",
            name: "hcactiviteedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/HcactiviteEdit"),
          },
          // Tutorats ===================================================================
          {
            path: "/tutorats",
            name: "tutorats",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListTutorat"),
          },
          {
            path: "/tutorats/create",
            name: "tutoratcreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/TutoratCreate"),
          },
          {
            path: "/tutorats/:idtutorat",
            name: "tutoratedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/TutoratEdit"),
          },
          // Evaluations =================================================================
          {
            path: "/tutorats/:idtutorat/evaluations/create",
            name: "evaluationcreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/EvaluationCreate"),
          },
          {
            path: "/tutorats/:idtutorat/evaluations/:ideval",
            name: "evaluationedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/EvaluationEdit"),
          },
          // formations prégraduées ======================================================
          {
            path: "/fpregrads",
            name: "fpregrads",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListFpregrad"),
          },
          {
            path: "/fpregrads/create",
            name: "fpregradcreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/FpregradCreate"),
          },
          {
            path: "/fpregrads/:idfpr",
            name: "fpregradedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/FpregradEdit"),
          },
          // thèses =====================================================================
          {
            path: "/theses",
            name: "theses",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/listes/ListThese"),
          },
          {
            path: "/theses/create",
            name: "thesecreate",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/TheseCreate"),
          },
          {
            path: "/theses/:idth",
            name: "theseedit",
            meta: { middleware: [auth] },
            component: () => import("@/components/rh/pages/TheseEdit"),
          },
          // Parcours cliniques ==========================================================
          {
            path: "/parcourscliniques",
            name: "parcourscliniques",
            meta: { middleware: [auth] },
            component: () =>
              import("@/components/rh/listes/ListParcoursclinique"),
          },
          {
            path: "/parcourscliniques/create",
            name: "parcourscliniquecreate",
            meta: { middleware: [auth] },
            component: () =>
              import("@/components/rh/pages/ParcourscliniqueCreate"),
          },
          {
            path: "/parcourscliniques/:idpc",
            name: "parcourscliniqueedit",
            meta: { middleware: [auth] },
            component: () =>
              import("@/components/rh/pages/ParcourscliniqueEdit"),
          },
        ],
        //=============================== fin APP RH ======================================
      },
    ],
  },
  // PDF : pdf avec url de l'app vue.js sont redirigés vers l'url de l'API où les fichiers sont stockés
  // => si user non connecté veut accéder au fichier avec son url, il est redirigé vers le fichier après login
  {
    //path: "/rhfiles/cv/cv_7_Bob_Marley.pdf",
    path: "/rhfiles/:catchAll(.*)",
    name: "pdfs",
    meta: { middleware: [auth] },
    beforeEnter: (to) => {
      return (window.location.href = process.env.VUE_APP_API_URL + to.path);
    },
  },
  // Erreurs
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () => import("@/views/NotFound"),
  },
];

export default routes;
