// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import posteAPI from "@/services/rh/poste.js";

const state = {
  postes: [],
  postesLoadStatus: 0,
};

const mutations = {
  setPostes(state, postes) {
    state.postes = postes;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setPostesLoadStatus(state, postes) {
    state.postesLoadStatus = postes;
  },
};

const actions = {
  loadPostes({ commit }) {
    commit("setPostesLoadStatus", 1);
    posteAPI
      .postesIndex()
      .then(function (response) {
        commit("setPostes", response.data);
        commit("setPostesLoadStatus", 2);
      })
      .catch(function () {
        commit("setPostes", []);
        commit("setPostesLoadStatus", 3);
      });
  },
};

const getters = {
  posteSelect: (state) => {
    // que  CDC, CDC-A, MA, MC
    // format [ {value:'value', label:'label'}, ....]
    return state.postes
      .filter((obj) => [1, 2, 3, 8].includes(obj.id))
      .map((obj) => {
        return { value: obj.id, label: obj.poste };
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
