// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios
import parcourscliniqueAPI from "@/services/rh/parcoursclinique.js";

const state = {
  parcourscliniques: [],
  parcourscliniquesLoadStatus: 0,
  parcoursclinique: {},
  parcourscliniqueLoadStatus: 0,
  parcourscliniqueCreateStatus: 0,
  parcourscliniqueLastCreatedId: 0,
  parcourscliniqueUpdateStatus: 0,
  parcourscliniqueDeleteStatus: 0,
  actions: {
    delete: "pc/deleteParcoursclinique",
  },
  colonnes: [
    {
      prop: "id",
      label: "ID",
      visible: false,
    },
    {
      prop: "id_med",
      label: "IDMED",
      typefiltre: "autocompl",
      visible: false,
    },
    {
      prop: "medecin",
      label: "Médecin",
      typefiltre: "autocompl",
      visible: true,
      lien: true,
      lienroute: {
        route: "med_parcourscliniques",
        params: { idmed: "id_med" },
      },
    },
    {
      prop: "activite",
      label: "Activite",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "taux",
      label: "Taux",
      typefiltre: "multichoix",
      visible: true,
    },
    {
      prop: "debut_date",
      label: "Début",
      typefiltre: "jdate",
      visible: true,
    },
    {
      prop: "fin_date",
      label: "Fin",
      typefiltre: "jdate",
      visible: true,
    },
  ],
};

const getters = {
  // dans form create pour vérifier si activité déjà attribué au méd.
  ParcourscliniqueInBD: (state) => {
    return state.parcourscliniques.map((t) => [
      t.id,
      t.id_med,
      t.id_activite,
      t.debut_date,
    ]);
  },
};

const mutations = {
  setParcourscliniques(state, parcourscliniques) {
    state.parcourscliniques = parcourscliniques;
  },
  setParcoursclinique(state, parcoursclinique) {
    state.parcoursclinique = parcoursclinique;
  },
  resetParcoursclinique(state, idmed) {
    state.parcoursclinique = {
      id: "",
      id_med: idmed,
      id_taux: "",
      debut_date: "",
      fin_date: "",
    };
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setParcourscliniquesLoadStatus(state, status) {
    state.parcourscliniquesLoadStatus = status;
  },
  setParcourscliniqueLoadStatus(state, status) {
    state.parcourscliniqueLoadStatus = status;
  },
  setParcourscliniqueCreateStatus(state, status) {
    state.parcourscliniqueCreateStatus = status;
  },
  setParcourscliniqueLastCreatedId(state, idpc) {
    state.parcourscliniqueLastCreatedId = idpc;
  },
  setParcourscliniqueUpdateStatus(state, status) {
    state.parcourscliniqueUpdateStatus = status;
  },
  setParcourscliniqueDeleteStatus(state, status) {
    state.parcourscliniqueDeleteStatus = status;
  },
  parcourscliniqueRemoveItem(state, idpc) {
    state.parcourscliniques = state.parcourscliniques.filter(
      (pc) => pc.id != idpc
    );
  },
};

const actions = {
  // charge l'ensemble des parcourscliniques
  loadParcourscliniques({ commit }) {
    commit("setParcourscliniquesLoadStatus", 1);
    parcourscliniqueAPI
      .parcourscliniqueIndex()
      .then(function (response) {
        // ajoute la col tri par défaut
        response.data.map((o, idx) => (o.tri_defaut = idx));
        commit("setParcourscliniques", response.data);
        commit("setParcourscliniquesLoadStatus", 2);
      })
      .catch(function () {
        commit("setParcourscliniques", []);
        commit("setParcourscliniquesLoadStatus", 3);
      });
  },
  // charge 1 parcoursclinique
  loadParcoursclinique({ commit }, idFpg) {
    commit("setParcourscliniqueLoadStatus", 1);
    parcourscliniqueAPI
      .parcourscliniqueShow(idFpg)
      .then(function (response) {
        commit("setParcoursclinique", response.data);
        commit("setParcourscliniqueLoadStatus", 2);
      })
      .catch(function () {
        commit("setParcoursclinique", {});
        commit("setParcourscliniqueLoadStatus", 3);
      });
  },
  // Créer un nouveau parcoursclinique :
  // met les données du parcoursclinique créé dans le JSON pour l'enregistre dans la BD via le Controller Laravel
  createParcoursclinique({ commit, dispatch }, data) {
    commit("setParcourscliniqueCreateStatus", 1);
    parcourscliniqueAPI
      .parcourscliniqueCreate(
        data.id_med,
        data.id_activite,
        data.id_taux,
        data.debut_date,
        data.fin_date
      )
      .then(function (response) {
        dispatch("loadParcourscliniques");
        commit("setParcourscliniqueCreateStatus", 2);
        commit("setParcourscliniqueLastCreatedId", response.data.id);
      })
      .catch(function () {
        commit("setParcourscliniqueCreateStatus", 3);
      });
  },
  // Met à jour les données d'un parcoursclinique
  updateParcoursclinique({ commit, dispatch }, data) {
    commit("setParcourscliniqueUpdateStatus", 1);
    parcourscliniqueAPI
      .parcourscliniqueUpdate(
        data.id,
        data.id_med,
        data.id_activite,
        data.id_taux,
        data.debut_date,
        data.fin_date
      )
      .then(function (response) {
        dispatch("loadParcourscliniques");
        commit("setParcoursclinique", response.data);
        commit("setParcourscliniqueUpdateStatus", 2);
      })
      .catch(function () {
        commit("setParcourscliniqueUpdateStatus", 3);
      });
  },
  // supprime 1 parcoursclinique
  deleteParcoursclinique({ commit, dispatch }, idpc) {
    commit("setParcourscliniqueDeleteStatus", 1);
    parcourscliniqueAPI
      .parcourscliniqueDelete(idpc)
      .then(function () {
        commit("setParcourscliniqueDeleteStatus", 2);
        dispatch("loadParcourscliniques");
      })
      .catch(function () {
        commit("setParcourscliniqueDeleteStatus", 3);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
