import * as API from "@/services/API";

export default {
  getUser(userId) {
    return API.apiClient.get("/users/" + userId);
  },
  getUsers() {
    return API.apiClient.get("/users");
  },
  // met à jour un item
  updateUser(id, name, email, isAdmin) {
    return API.apiClient.put("/users/" + id, {
      name: name,
      email: email,
      is_admin: isAdmin,
    });
  },
  // crée un nouvel item
  createUser(name, email, isAdmin) {
    return API.apiClient.post("/users", {
      name: name,
      email: email,
      is_admin: isAdmin,
    });
  },
  // supprime un item
  deleteUser(userId) {
    return API.apiClient.delete("/users/" + userId);
  },
  // récupère les prefs pour les tables de l'user connecté
  getUserPrefsTables() {
    return API.apiClient.get("/user-prefs-tables-show");
  },
  // met à jour les prefs pour les tables de l'user connecté
  majUserPrefsTables(prefs_tables) {
    return API.apiClient.put("/user-prefs-tables-maj", {
      prefs_tables: prefs_tables,
    });
  },
};
