import * as API from "@/services/API";
// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  fpregradIndex: function () {
    return API.apiClient.get("/fpregrads");
  },
  // retourne un item (ID)
  fpregradShow: function (id) {
    return API.apiClient.get("/fpregrads/" + id);
  },
  // pour créer un nouvel item
  fpregradCreate: function (id_med, id_fac, titre, titre_prec, annee) {
    return API.apiClient.post("/fpregrads", {
      id_med: id_med,
      id_fac: id_fac,
      titre: titre,
      titre_prec: titre_prec,
      annee: annee,
    });
  },
  // met à jour un item
  fpregradUpdate: function (id, id_med, id_fac, titre, titre_prec, annee) {
    return API.apiClient.put("/fpregrads/" + id, {
      id_med: id_med,
      id_fac: id_fac,
      titre: titre,
      titre_prec: titre_prec,
      annee: annee,
    });
  },
  // supprime un item
  fpregradDelete: function (fprId) {
    return API.apiClient.delete("/fpregrads/" + fprId);
  },
};
