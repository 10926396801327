import * as API from "@/services/API";

// récupère les routes des API de Laravel (routes/api.php)
// route génère les routes pour toutes les opérations :
//pour la liste des routes Laravel dans un terminal -> php artisan route:list

export default {
  // liste items
  contratIndex: function () {
    return API.apiClient.get("/contrats");
  },
  // retourne un item (ID)
  contratShow: function (contId) {
    return API.apiClient.get("/contrats/" + contId);
  },
  // pour créer un nouvel item
  contratCreate: function (
    id_med,
    id_service,
    id_poste,
    id_taux,
    id_permis,
    id_type,
    coromu,
    activite_xurg,
    activite_xurg_prec,
    debut_date,
    fin_prevue_date,
    fin_effect_date,
    id_fin_motif,
    fin_motif_prec,
    depart_prev_mois,
    depart_prev_proj
  ) {
    return API.apiClient.post("/contrats", {
      id_med: id_med,
      id_service: id_service,
      id_poste: id_poste,
      id_taux: id_taux,
      id_permis: id_permis,
      id_type: id_type,
      coromu: coromu,
      activite_xurg: activite_xurg,
      activite_xurg_prec: activite_xurg_prec,
      debut_date: debut_date,
      fin_prevue_date: fin_prevue_date,
      fin_effect_date: fin_effect_date,
      id_fin_motif: id_fin_motif,
      fin_motif_prec: fin_motif_prec,
      depart_prev_mois: depart_prev_mois,
      depart_prev_proj: depart_prev_proj,
    });
  },
  // met à jour un item
  contratUpdate: function (
    id,
    id_med,
    id_service,
    id_poste,
    id_taux,
    id_permis,
    id_type,
    coromu,
    activite_xurg,
    activite_xurg_prec,
    debut_date,
    fin_prevue_date,
    fin_effect_date,
    id_fin_motif,
    fin_motif_prec,
    depart_prev_mois,
    depart_prev_proj
  ) {
    return API.apiClient.put("/contrats/" + id, {
      id_med: id_med,
      id_service: id_service,
      id_poste: id_poste,
      id_taux: id_taux,
      id_permis: id_permis,
      id_type: id_type,
      coromu: coromu,
      activite_xurg: activite_xurg,
      activite_xurg_prec: activite_xurg_prec,
      debut_date: debut_date,
      fin_prevue_date: fin_prevue_date,
      fin_effect_date: fin_effect_date,
      id_fin_motif: id_fin_motif,
      fin_motif_prec: fin_motif_prec,
      depart_prev_mois: depart_prev_mois,
      depart_prev_proj: depart_prev_proj,
    });
  },
  // supprime un item
  contratDelete: function (contId) {
    return API.apiClient.delete("/contrats/" + contId);
  },
};
