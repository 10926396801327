// source pour axios : https://stackoverflow.com/questions/48455175/how-to-get-data-from-api-in-vuex-using-axios

import tauxAPI from "@/services/rh/taux.js";

const state = {
  taux: [],
  tauxLoadStatus: 0,
};

const mutations = {
  setTaux(state, taux) {
    state.taux = taux;
  },
  // Status : 1 = processus en cours, 2 = succès, 3 = échec
  setTauxLoadStatus(state, taux) {
    state.tauxLoadStatus = taux;
  },
};

const actions = {
  loadTaux({ commit }) {
    commit("setTauxLoadStatus", 1);
    tauxAPI
      .tauxIndex()
      .then(function (response) {
        commit("setTaux", response.data);
        commit("setTauxLoadStatus", 2);
      })
      .catch(function () {
        commit("setTaux", []);
        commit("setTauxLoadStatus", 3);
      });
  },
};

const getters = {
  tauxSelect: (state) => {
    // format [ {value:'value', label:'label'}, ....]
    return state.taux.map((obj) => {
      return { value: obj.id, label: obj.taux_descri, taux: obj.taux };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
